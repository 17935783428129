import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { 
    IonAlert,
    IonAvatar, IonBadge, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, 
    IonCardTitle, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonLoading, IonPage, 
    IonTitle, IonToggle, IonToolbar, useIonToast
} from "@ionic/react"
import { 
    chevronForward, card, moon, chatbubble, helpCircle, location as locationIcon,
    document as documentIcon, logOut as myLog, notifications as bellIcon
} from "ionicons/icons";
import { Capacitor } from '@capacitor/core';

import { AppContext } from "../../contexts/AppContextProvider";
import { getUserNames, logout, setColorMode, setPushAccess } from "../../Services/State";
import { addNativePushRegListeners, appAuthDomain, checkPushPermission, deleteBiometricCredentials, getInitials, getPushPermission, hapticsImpactHeavy, hapticsImpactLight, hapticsVibrate, localDomain, makeRequests, onDeviceStorage, ShowAlertInterface, subscribeToPush, unsubscribeToPush } from "../../Services/utils";

import './index.css';
const Menu:React.FC = ()=>{
    const { state, dispatch } = useContext(AppContext);

    const location = useLocation();
    const locationState = (location.pathname).substring(1, (location.pathname).length);
    const refDarkMode = useRef<HTMLIonToggleElement>(null);
    const [showLoadingState, setShowLoading] = useState({showLoadingMessage:'c...', showLoading: false});
    const [showAlertState, setShowAlertState] = useState<ShowAlertInterface>({header: "", subHeader: "", message: "", inputs:[], buttons: [], showAlert: false});
    const [present, dismiss] = useIonToast();

    const history = useHistory();
    
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
    prefersDark.onchange = (e) => {
        checkToggle(prefersDark.matches);
    };
    const checkToggle = useCallback((shouldCheck: boolean)=>{
        var requestObj:any = {method: "POST", url: appAuthDomain("api/profile?appType=iot&action=updateProfile"), data: {themeMode: shouldCheck}};
        requestObj.headers = {'Content-Type': 'multipart/form-data'};
        makeRequests(state, requestObj).then(response=>{
            console.log(response);
        });
        var chosenMode = shouldCheck?"dark":"light";
        onDeviceStorage('set', {'themeMode': (chosenMode==="dark")?(true):(false)});
        dispatch(setColorMode(chosenMode));
        hapticsImpactLight();
      }, [state, dispatch]);
    const switchMode = (_checked: any)=>{
        checkToggle(_checked);
    };

    // const locAccessCallback = useCallback((access: any)=>{
    //     dispatch(setLocationAccess(access));
    // }, [dispatch]);
    // const preLocationRequest = useCallback(()=>{
    //     var buttonActions = [
    //       {
    //           text: 'Give it a try',
    //           handler: () => {
    //             getCurrentPosition().then(posRes=>{
    //                 console.log(posRes);
    //                 locAccessCallback(posRes);
    //             })
    //         }
    //       },
    //       {
    //           text: 'Not now',
    //           role: 'cancel',
    //           cssClass: 'secondary',
    //           handler: () => {
    //           }
    //       },
    //       {
    //           text: 'Allow while using App',
    //           handler: () => {
    //             getCurrentPosition().then(posRes=>{
    //                 console.log(posRes);
    //                 locAccessCallback(posRes);
                    
    //             })
    //         }
    //       }
    //     ];
    //     var alertStateVars = {
    //         header: "For best experience",
    //         subHeader: "Allow Geolocation", 
    //         inputs: [],
    //         message: "Your phone will ask for Geolocation permission, please select 'Allow' to let our services work.",
    //         buttons: buttonActions
    //     };
    //     setShowAlertState({...alertStateVars, showAlert: true});
    // }, [locAccessCallback]);
    // const updateLocPermission  = (checked: any)=>{
    //     if (checked) {
    //         checkLocationPermission().then(permiRes=>{
    //             if (permiRes.location === "prompt") {
    //                 preLocationRequest();
    //             } else {
    //                 getCurrentPosition().then(permiRes=>{
                        
    //                 })
    //             }
    //         });
    //     } else {
    //         if (navigator.permissions) {
    //         } else {
    //             console.log(navigator.permissions)
    //         }
    //     }
    // }
    const doLogout = useCallback(()=>{
        var buttonActions = [
            {
                text: 'Not now',
                role: 'cancel',
                cssClass: 'secondary',
                handler: () => {
                    // window.history.back();
                }
            },
            {
                text: 'Continue',
                handler: () => {

                    present({
                        position: "top",
                        buttons: [{ text: 'hide', handler: () => dismiss() }],
                        message: 'Logged out ...',
                    });
                    dispatch(logout());
                    history.push("/login");
                    setTimeout(() => {
                        // localStorage.clear();
                        deleteBiometricCredentials();
                        onDeviceStorage('clear', null);
                        window.location.reload();
                    }, 500);
                }
            }
        ];
        var alertStateVars = {
            header: "About to log out",
            subHeader: "", 
            message: "Are you sure?",
            inputs: [],
            buttons: buttonActions
        };
        setShowAlertState({...alertStateVars, showAlert: true});
    }, [dispatch, history, dismiss, present]);

    const updatePushSubscriptionOnServer = (subscriptionAndPlatform: any)=>{
        // console.log('subscriptionAndPlatform: ', subscriptionAndPlatform);
        dismiss();
        let subscription = subscriptionAndPlatform.subscription;
        let endpoint; let key; let token; let type = "web"; 
        if (subscriptionAndPlatform.platform === "ios") type = "apn";
        if (subscriptionAndPlatform.platform === "android") type = "fcm";

        var uint8Array_p256dh:any; var uint8Array_auth:any;
        var subscriptionBody:any = {};
        if (subscription) {
            if (type === "web") {
                endpoint = subscription.endpoint; 
                key = subscription.getKey('p256dh'); 
                token = subscription.getKey('auth');

                uint8Array_p256dh = new Uint8Array(subscription.getKey('p256dh'));
                uint8Array_auth = new Uint8Array(subscription.getKey('auth'));

                subscriptionBody = {
                    endpoint: endpoint ? endpoint : null,
                    key: key ? btoa(String.fromCharCode.apply(null, uint8Array_p256dh)) : null,
                    token: token ? btoa(String.fromCharCode.apply(null, uint8Array_auth)) : null
                }
            } else {
                token = subscription.token;
                subscriptionBody = {
                    token: token ? token : null
                }
            }
        };
        var reqOptions = {
            url: appAuthDomain("api/pushSubscription?appType=iot&action=subscription"),
            method: "POST",
            data: {
                type,
                subscription: JSON.stringify(subscriptionBody)
            }
        };
        setShowLoading((s:any)=>({...s, showLoadingMessage: "Registering device for notifications...", showLoading: true}));
        makeRequests(state, reqOptions).then(pusSubscriptionsRes=>{
            setShowLoading((s:any)=>({...s, showLoading: false}));
            if (pusSubscriptionsRes.success) {
                hapticsImpactHeavy();
                present({
                    position: "top",
                    buttons: [{ text: 'Done', handler: () => dismiss() }],
                    message: pusSubscriptionsRes.msg,
                    duration: 2000
                });
                if (subscriptionBody.token == null) {
                    dispatch(setPushAccess(false));
                }
            } else {
                hapticsVibrate(300);
                var buttonActions = [
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            dispatch(setPushAccess(false));
                        }
                    },
                    {
                        text: 'Retry',
                        handler: () => {
                            updatePushSubscriptionOnServer(subscriptionAndPlatform);
                        }
                    }
                ];
                var alertStateVars = {
                    header: pusSubscriptionsRes.msg,
                    subHeader: pusSubscriptionsRes.msg2,
                    message: pusSubscriptionsRes.msg3,
                    buttons: buttonActions,
                    inputs: []
                }
                setShowAlertState({...alertStateVars, showAlert: true});
            }
        })
    }
    const nativePushCallback = (eventInfo: any)=>{
        var alertStateVars:any = {};
        if (eventInfo.token) {
            alertStateVars = {
                header: "NativePushCallback",
                subHeader: "eventInfo Token",
                message: eventInfo.token,
                buttons: []
            };
            if (eventInfo.token.value) {
                updatePushSubscriptionOnServer({platform: Capacitor.getPlatform(), subscription: {token: eventInfo.token.value}});                
            } else {
                setShowAlertState({...alertStateVars, showAlert: true});
            }
        } else if (eventInfo.error) {
            alertStateVars = {
                header: "NativePushCallback",
                subHeader: "eventInfo Error",
                message: eventInfo,
                buttons: []
            }
            setShowAlertState({...alertStateVars, showAlert: true});
        }
    }
    const updatePushPermission  = (checked: any)=>{
        var buttonActions:any = [];
        var alertStateVars;
        if (checked) {
            checkPushPermission().then(permCheckRes=>{
                // console.log(permCheckRes);
                if ('success' in permCheckRes) {
                    // getPermissions({name: 'pushManager'}).then(webPermRes=>{
                    //     console.log(webPermRes)
                    // })
                    alertStateVars = {
                        header: permCheckRes.msg,
                        subHeader: permCheckRes.msg2,
                        message: permCheckRes.msg3,
                        buttons: buttonActions,
                        inputs: []
                    }
                    setShowAlertState({...alertStateVars, showAlert: true});
                    
                    dispatch(setPushAccess(false));
                } else {
                    if (permCheckRes.receive !== 'granted') {
                        getPushPermission().then((permRes)=>{
                            console.log('permRes: ', permRes);
                            if ('receive' in permRes) {
                                if (permRes.receive === 'denied') {
                                    buttonActions = [
                                        // {
                                        //     text: 'Cancel',
                                        //     role: 'cancel',
                                        //     cssClass: 'secondary',
                                        //     handler: () => {
                                        //         // openSettings();
                                        //     }
                                        // },
                                    ]
                                    alertStateVars = {
                                        header: "Permission was denied",
                                        subHeader: "You can try again.", 
                                        message: "This permission was dined before. Go to your phone settings in order to explicitly grant this permission.",
                                        buttons: buttonActions,
                                        inputs: []
                                    };
                                    setShowAlertState({...alertStateVars, showAlert: true});
                                } else {
                                    if (Capacitor.getPlatform() === "web") {
                                        subscribeToPush().then(subscriptionRes=>{
                                            updatePushSubscriptionOnServer({platform: Capacitor.getPlatform(), subscription: subscriptionRes});
                                            dispatch(setPushAccess(checked));
                                        }).catch(err=>{
                                            console.log(err)
                                        });
                                    } else {
                                        addNativePushRegListeners(nativePushCallback).then((listenerAddResult)=>{
                                            subscribeToPush().then(subscriptionRes=>{
                                                dispatch(setPushAccess(checked));
                                            });
                                        });
                                    }
                                }
                            } else {
                                console.log("Handle differently");

                            }
                        });
                    } else {
                        if (Capacitor.getPlatform() === "web") {
                            subscribeToPush().then(subscriptionRes=>{
                                updatePushSubscriptionOnServer({platform: Capacitor.getPlatform(), subscription: subscriptionRes});
                                dispatch(setPushAccess(checked));
                            });
                        } else {
                            addNativePushRegListeners(nativePushCallback).then((listenerAddResult)=>{
                                subscribeToPush().then(subscriptionRes=>{
                                    dispatch(setPushAccess(checked));
                                });
                            })
                        }
                    };
                }
            });
        } else {
            checkPushPermission().then(permCheckRes=>{
                if ('success' in permCheckRes) {
                    present({
                        position: "top",
                        buttons: [{ text: 'Done', handler: () => dismiss() }],
                        message: `Error: ${permCheckRes.msg3}`,
                        duration: 2000
                    });
                } else {
                    unsubscribeToPush().then(revokeRes=>{
                        hapticsImpactLight();
                        present({
                            position: "top",
                            buttons: [{ text: 'Done', handler: () => dismiss() }],
                            message: "Notifications: off",
                            duration: 2000
                        });
                        dispatch(setPushAccess(false));
                    });
                }
            });
        }
    }
    useEffect(()=>{
        var tabBar = document.getElementById("navigationTabBarRef");
        if(tabBar){
            if (locationState === "menu") {
                tabBar.style.transform = "";
                tabBar.style.display = `flex`;
            }
        }
    })

    return <IonPage>
        <IonHeader
        mode='ios'>
            <IonToolbar>
                <IonTitle>Menu</IonTitle>
            </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
            <IonHeader mode='ios' collapse="condense">
                <IonToolbar>
                    <IonTitle size="large">Menu</IonTitle>
                </IonToolbar>
            </IonHeader>
            <div>
                <br/>
                <IonCard 
                    mode='ios'
                    routerLink="/profile"
                    type="button"
                >
                    {
                        (state.user.profileNotifyCount>0)?(
                        <IonBadge className="menuProfileSetupBadge" color="danger">{
                            (state.user.profileNotifyCount > 99)?(
                            `${state.user.profileNotifyCount}+`
                            ):(
                            state.user.profileNotifyCount
                            )
                        }</IonBadge>
                        ):("")
                    }
                    <br/>
                    <div className="menuProfile">
                        <IonAvatar className='profileAVTR'>
                            {
                                (state.auth.user && state.auth.user.profilePic)?(
                                    <img src={appAuthDomain(state.auth.user.profilePic)} alt="profilePic" />
                                    ):(
                                    <div className='profileAVTRNoPic'>
                                        <span>
                                            {getInitials(getUserNames(state) || "")}
                                        </span>
                                    </div>
                                )
                            }
                        </IonAvatar>
                        <div className="profileHintsPart">
                            <IonCardTitle className="menuProfileNames">{getUserNames(state)}</IonCardTitle>
                            <IonCardSubtitle>{state.user.type}</IonCardSubtitle>
                            <p>Manage your account (Email, Phone, etc.)...</p>
                        </div>
                        <div className="chevronPart">
                            <IonIcon icon={chevronForward} />
                        </div>
                    </div>
                </IonCard>
                <IonCard mode='ios'>
                    <IonCardHeader>
                        <IonCardTitle>
                            Settings
                        </IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent className="cardContent">
                        <IonList className="cardContentList">
                            <IonItem routerLink="/payments">
                                <IonIcon mode='ios' icon={card} />
                                <IonLabel>Payments/Billing</IonLabel>
                            </IonItem>
                            <IonItem>
                                <IonIcon icon={bellIcon} className="component-icon component-icon-dark" />
                                <IonLabel className="myLabels"> Push notifications</IonLabel>
                                <IonToggle color="primary" 
                                    mode='ios'
                                    checked={state.user.pushAccess} 
                                    onIonChange={(e:any)=>updatePushPermission(e.detail.checked)}
                                />
                            </IonItem>
                            <IonItem style={{display: "none"}}>
                                <IonIcon icon={locationIcon}className="component-icon component-icon-dark"  />
                                <IonLabel className="myLabels"> Location Access</IonLabel>
                                <IonToggle color="primary" 
                                mode='ios'
                                    checked={state.user.locationAccess} 
                                    // onIonChange={(e)=>updateLocPermission(e.detail.checked)}
                                />
                            </IonItem> 
                            <IonItem type='button'>
                                <IonIcon slot="start" icon={moon} className="component-icon component-icon-dark" />
                                <IonLabel>Dark Mode</IonLabel>
                                <IonToggle 
                                mode='ios'
                                checked={(state.ui.colorMode==="dark")?(true):(false)}
                                ref={refDarkMode} 
                                onIonChange={e => switchMode(e.detail.checked)}
                                // disabled
                                />
                            </IonItem>
                        </IonList>
                    </IonCardContent>
                </IonCard>
                <IonCard mode='ios'>
                    <IonCardHeader>
                        <IonCardTitle>
                            More
                        </IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent className="cardContent">
                        <IonList className="cardContentList">
                            <IonItem href={"https://appimate.com/about.html"}>
                                <IonIcon icon={chatbubble} />
                                <IonLabel>About us</IonLabel>
                            </IonItem>
                            <IonItem href={localDomain("help.html")}>
                                <IonIcon icon={helpCircle} />
                                <IonLabel>Help</IonLabel>
                            </IonItem>
                            <IonItem href={localDomain("terms.html")}>
                                <IonIcon icon={documentIcon} />
                                <IonLabel>Terms</IonLabel>
                            </IonItem>
                        </IonList>
                    </IonCardContent>
                </IonCard>
                <IonCard mode='ios'>
                    <IonItem onClick={doLogout}>
                        <IonIcon slot="start" icon={myLog} className="component-icon component-icon-dark" />
                        <IonLabel>Log out</IonLabel>
                    </IonItem>
                </IonCard>
                <br/>
                <br/>
                <br/>
            </div>
        </IonContent>
        <IonLoading
            mode='ios'
            isOpen={showLoadingState.showLoading}
            onDidDismiss={() => setShowLoading({...showLoadingState, showLoading: false})}
            message={showLoadingState.showLoadingMessage}
        />
        <IonAlert
            mode='ios'
            isOpen={showAlertState.showAlert}
            onDidDismiss={() => setShowAlertState({...showAlertState, showAlert: false})}
            header={showAlertState.header}
            subHeader={showAlertState.subHeader}
            message={showAlertState.message}
            inputs={showAlertState.inputs}
            buttons={showAlertState.buttons}
        />
    </IonPage>
}
export default Menu;