import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { 
    IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonBackButton, IonButtons, IonAlert, IonButton, IonIcon, IonCard, IonCardContent, IonCardTitle, useIonViewDidEnter, 
} from "@ionic/react";
import {arrowForwardCircle, card, checkmarkCircle, chevronBack } from "ionicons/icons";
import { AppContext } from "../../contexts/AppContextProvider";
import { setItemsToPay, setMicroTransactions } from "../../Services/State";
import { localDomain, makeRequests, ShowAlertInterface } from "../../Services/utils";


import './index.css';
const Payments: React.FC<any> = ({firstModalRef})=>{
    const { state, dispatch } = useContext(AppContext);

    let location = useLocation();
    const [selectedOptions, setSelectedOptions] = useState<any>({});
    const [completeSelection, setCompleteSelection] = useState(true);
    const [showAlertState, setShowAlertState] = useState<ShowAlertInterface>({header: "Alert", subHeader: "If this takes too long, just close tha App and start afresh.", message: "", buttons: [], showAlert: false});

    const itemsToPay = state.microTransactions.itemsToPay;
    var pageIn = location.pathname;
    pageIn = pageIn.substring(1, pageIn.length);


    const selectSubscription = (subscription: any)=>{
        var subsKey = subscription.subsKey;
        var personalizationSubscription:any = {};
        if (!(subsKey in selectedOptions )) {
            personalizationSubscription[subsKey] = subscription;
        } else {
            delete personalizationSubscription[subsKey];
        }
        setSelectedOptions({...personalizationSubscription});
    }
    const donePackageSelection = ()=>{
        var choicekey = Object.keys(selectedOptions)[0];
        var selectedProduct = [{...selectedOptions[choicekey]}];
        dispatch(setMicroTransactions({selectedProduct}));
        dispatch(setMicroTransactions({show: true, presentingElement: firstModalRef}));
    }
    const manageCards = ()=>{
        dispatch(setMicroTransactions({ show: true, presentingElement: firstModalRef }));
    }
    const submitSubscription = ()=>{
        // (state.microTransactions.confirmService)(state);
    }
    const clickOnChosenCard = ()=>{
        var buttons = [
            {
                text: 'back',
                role: 'cancel',
                cssClass: 'secondary',
                handler: ()=>{
                }
            },
            {
                text: 'Remove this card',
                handler: ()=>{
                    dispatch(setMicroTransactions({ cardDetails: {} }));
                }
            },
            {
                text: 'Use another card',
                handler: ()=>{
                    dispatch(setMicroTransactions({ show: true, presentingElement: firstModalRef }));
                }
            }
        ]
        setShowAlertState({...showAlertState, header: "Update selected card?", subHeader: "", message: "Do you want to shange the selected Card.", buttons, showAlert: true})
    };
    useEffect(()=>{
        var allSet = false;
        if (Object.keys(selectedOptions).length > 0) {
            allSet = true;
        }
        setCompleteSelection(allSet);
    }, [selectedOptions]);
    useEffect(() => {
        var dashboardPages = ['payments', ''], showBottomNav = false, bottomNav = document.getElementById("defaultIonicTabBar");
        if (!dashboardPages.includes(pageIn)) showBottomNav = true; 
        if (bottomNav) bottomNav.style.display = "none";
        return ()=> {
            if (bottomNav) if (showBottomNav) bottomNav.style.display = "flex";
        }
    },[pageIn]);
    useIonViewDidEnter(()=>{
        let requestObject = {ur: localDomain("api/bills")}
        makeRequests(state, requestObject).then(resp=>{
            if (resp.success) {
                dispatch(setItemsToPay(resp.data));
            }
        })
    });

    return (
        <IonPage>
            <IonHeader mode='ios'>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="home" text="Back" icon={chevronBack} />
                    </IonButtons>
                    <IonTitle>Payments</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonHeader collapse="condense" mode='ios'>
                    <IonToolbar>
                        <IonTitle size="large">Payments</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonButton size="small" className="manageCardsBtn" onClick={manageCards}>Manage Cards <IonIcon icon={card}/></IonButton>
                <br/>
                {
                itemsToPay.map((option: any, key: number)=>{
                        var thisOpt = option.subsKey;
                        return <div className='subscriptOptsHolder' key={key}>
                            <IonCard  className={ 'subscriptionOptions '+( ((thisOpt in selectedOptions))?(`selectedSubscriptionOptions`):('') ) } 
                            onClick={()=>{selectSubscription( option )}}
                            >
                            <div className='subsOptionCheckPart'>
                                <div className='checkPartIcons'>
                                {
                                    ((thisOpt in selectedOptions))?( <IonIcon  icon={checkmarkCircle}/> ):( "" )
                                }
                                </div>
                            </div>
                            <div className='subsOptionDetailsPart'>
                                <IonCardContent> 
                                    <IonCardTitle> { option.label } </IonCardTitle>
                                    { option.description }
                                </IonCardContent>
                            </div>
                            <div className='subsOptionpricePart'>
                                <IonCardTitle className='priceAmount'>R { option.amount.value } </IonCardTitle>
                            </div>
                        </IonCard>
                        {
                            ((Object.keys(state.microTransactions.cardDetails).length > 0)&&(thisOpt in selectedOptions))?(
                                <IonCard className='selectedCard' onClick={clickOnChosenCard}>
                                    <span className='cardHolder'>{state.microTransactions.cardDetails.cardHolder}</span> <span className='cardBrand'>{state.microTransactions.cardDetails.cardBrand}</span> 
                                    <br/>
                                    <br/>
                                    <span className='cardNumber'>**** **** **** {(state.microTransactions.cardDetails.last4Digits).slice(-4)}</span>
                                    <br/>
                                    <span className='cardDate'>{state.microTransactions.cardDetails.month}/{state.microTransactions.cardDetails.year}</span>
                                    <span className='cardcvv'>***</span>
                                    <br/>
                                </IonCard>
                            ):("")
                        }
                        </div>;
                    })
                }
                <br/>
                <br/>
                <br/>
                <br/>
            </IonContent>
                {
                    (Object.keys(state.microTransactions.cardDetails).length > 0)?(
                        <IonButton className={"selectionComplete selectionComplete-"+completeSelection} onClick={submitSubscription} disabled={!completeSelection}>
                            Submit <IonIcon icon={checkmarkCircle} className="welcomeSlideBtnIcons"/>
                        </IonButton>
                    ):(
                        <IonButton className={"selectionComplete selectionComplete-"+completeSelection} onClick={donePackageSelection} disabled={!completeSelection}>
                            Continue <IonIcon icon={arrowForwardCircle} className="welcomeSlideBtnIcons"/>
                        </IonButton>
                    )
                }
                <br/>
                <br/>
            <IonAlert
                mode='ios'
                isOpen={showAlertState.showAlert}
                onDidDismiss={() => setShowAlertState({...showAlertState, showAlert: false})}
                header={showAlertState.header}
                subHeader={showAlertState.subHeader}
                message={showAlertState.message}
                buttons={showAlertState.buttons}
            />
        </IonPage>
    );

}
export default Payments;