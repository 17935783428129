import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import {
    IonAlert,
    IonApp, IonIcon, IonLabel, IonLoading, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs,
    setupIonicReact, useIonToast
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { 
    // calendar, 
    // cog, 
    home, menu, 
    notifications as bellIcon 
} from 'ionicons/icons';

import { Capacitor } from '@capacitor/core';
import { App as AppState } from '@capacitor/app';
import { SplashScreen } from '@capacitor/splash-screen';

import { AppContext } from './contexts/AppContextProvider';
import { loggedIn, setAppState, setColorMode, setInitialAuth, setIsOnline, setLocationAccess, setPushAccess } from './Services/State';
import { checkPushPermission, localDomain, makeRequests, manageDeliveredNotifications, onDeviceStorage, ShowAlertInterface } from './Services/utils';

import LandingPage from './pages/LandingPage';
import Login from './pages/Login';
import Home from './pages/Home';
import Schedules from './pages/Schedules';
import Automations from './pages/Automations';
import Notifications from './pages/Notifications';
import Menu from './pages/Menu';
import ListOfThings from './pages/ListOfThings';
import Profile from './pages/Profile';
import Payments from './pages/Payments';
// import Dashboard from './pages/Dashboard';

import AuthRoute from './rerouting/AuthRoute';


/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './pages/index.css';

setupIonicReact();
const App:React.FC = ()=>{
    const { state, dispatch } = useContext(AppContext);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [showLoadingState, setShowLoading] = useState({showLoadingMessage: "Loading ...", showLoading: false, triggered: false});
    const [showAlertState, setShowAlertState] = useState<ShowAlertInterface>({header: "Alert", subHeader: "If this takes too long, just close tha App and start afresh.", message: "", buttons: [], showAlert: false});
    const [present, dismiss] = useIonToast();

    const routerRef = useRef<HTMLIonRouterOutletElement | null>(null);
    document.body.classList.toggle('dark', (state.ui.colorMode==="dark")?(true):(false));
    var conditionalLandingPage = {value: true, page: "home"};
    
    const visibilityCallback = useCallback((isActive:any) => {
        var requestObj = {method: "POST", url: localDomain('api/requests'), data: {actionType: 'openApp', onlineState: isActive}};
        makeRequests(state, requestObj);
        dispatch(setAppState(isActive));
    }, [state, dispatch]);

    useEffect(()=>{
        onDeviceStorage('get', 'themeMode').then((res: any)=>{
            var colorMode = (res==='true')?'dark':'light';
            dispatch(setColorMode(colorMode));
        });
        if (isAuthenticated && state.initialAuth) {

            onDeviceStorage('get', 'userInfo').then((userInfo: any)=>{
                var user = JSON.parse(userInfo);
                user.initialAuth = true;
                dispatch(loggedIn(user));
                if (!userInfo.setUp) {

                };
            });
        };
    }, [dispatch, isAuthenticated, state.initialAuth]);
    useEffect(()=>{
        if (!state.initialAuth) {
          onDeviceStorage('get', 'userInfo').then(async (fetchedInfo: any)=>{
            if (fetchedInfo) {
              var userInfo = JSON.parse(fetchedInfo);
              if (userInfo.accessToken) {
                if (userInfo.accessToken!=="") {
                  setIsAuthenticated(true);
                  checkPushPermission().then(permi=>{
                    if (permi.receive === "granted") {
                        dispatch(setPushAccess(true));
                    };
                  });
                  checkPushPermission().then(permi=>{
                      if (permi.location === "granted") {
                          dispatch(setLocationAccess(true));
                      };
                  });
                  manageDeliveredNotifications({});
                }
              }
            };
    
            //For as long we checked Auth status, set the following settings regardless of the Auth state of the user.
            dispatch(setInitialAuth(true));
            window.addEventListener('online', e=>{
              // present({mode: "ios",  position: "top", buttons: [{ text: 'hide', handler: () => dismiss() }], message: "Connection recoverd.", duration: 2000});
              dispatch(setIsOnline(true));
            });
            window.addEventListener('offline', e=>{
              present({mode: "ios", position: "top", buttons: [{ text: 'hide', handler: () => dismiss() }], message: "Connection lost, offline", duration: 2000});
              dispatch(setIsOnline(false));
            });
            AppState.addListener('appStateChange', ({ isActive }) => {
              visibilityCallback(isActive);
            });
            AppState.addListener('appUrlOpen', data => {
              // console.log('App opened with URL:', data);
            });
            AppState.addListener('appRestoredResult', data => {
              // console.log('Restored state:', data);
            });
            SplashScreen.hide();
          })
        };
    }, [state.initialAuth, dispatch, present, dismiss, visibilityCallback]);

    if (((Capacitor.getPlatform() === 'ios')||(Capacitor.getPlatform() === 'android'))&&(isAuthenticated)) {
        conditionalLandingPage = {value: false, page: "home"};
    };

    return <IonApp>
        {
            (!state.initialAuth)?(
                <div className='loaderAfterSplashScreen'>
                <div className='appLogo'>
                    <img decoding="async" loading="lazy" src="/assets/icons/icon-512.webp" alt='AppLogo'/>
                </div>
                </div>
            ):(
            <>
            <IonReactRouter >
                <IonTabs>
                    <IonRouterOutlet mode='ios' ref={routerRef}>
                        <Route exact path="/login">
                            <Login name="Login" setShowLoading={setShowLoading} setShowAlertState={setShowAlertState}/>
                        </Route>
                        <Route exact path="/register">
                            <Login name="Create Account" setShowLoading={setShowLoading} setShowAlertState={setShowAlertState}/>
                        </Route>
                        <Route exact path="/otp">
                            <Login name="OTP" setShowLoading={setShowLoading} setShowAlertState={setShowAlertState}/>
                        </Route>
                        <Route exact path="/forgotPassword">
                            <Login name="Forgot Password" setShowLoading={setShowLoading} setShowAlertState={setShowAlertState}/>
                        </Route>
                        <Route exact path="/resetPassword">
                            <Login name="Reset Password" setShowLoading={setShowLoading} setShowAlertState={setShowAlertState}/>
                        </Route>
                        <AuthRoute exact path="/home" isAuthorized={isAuthenticated}>
                            <Home routerRef={routerRef} />
                        </AuthRoute>
                        <AuthRoute exact path="/schedules" isAuthorized={isAuthenticated}>
                            <Schedules />
                        </AuthRoute>
                        <AuthRoute exact path="/automations" isAuthorized={isAuthenticated}>
                            <Automations />
                        </AuthRoute>
                        <AuthRoute exact path="/home" isAuthorized={isAuthenticated}>
                            <Home routerRef={routerRef} setShowLoading={setShowLoading} setShowAlertState={setShowAlertState}/>
                        </AuthRoute>
                        <AuthRoute exact path="/notifications" isAuthorized={isAuthenticated}>
                            <Notifications />
                        </AuthRoute>
                        <AuthRoute exact path="/menu" isAuthorized={isAuthenticated}>
                            <Menu />
                        </AuthRoute>
                        <AuthRoute exact path="/things" isAuthorized={isAuthenticated}>
                            <ListOfThings myPresentingElemRef={routerRef} />
                        </AuthRoute>
                        <AuthRoute exact path="/profile" isAuthorized={isAuthenticated}>
                            <Profile />
                        </AuthRoute>
                        <AuthRoute exact path="/payments" isAuthorized={isAuthenticated}>
                            <Payments />
                        </AuthRoute>
                        {
                            (conditionalLandingPage.value)?(
                            <Route exact path="/">
                                <LandingPage />
                            </Route>
                            ):(
                            <Route exact path="/">
                                <Redirect to={`/${conditionalLandingPage.page}`} />
                            </Route>
                            )
                        }
                    </IonRouterOutlet>
                    <IonTabBar mode='ios' slot="bottom" id="navigationTabBarRef" style={{transition: "0.1s ease-in-out", display: "none"}}>
                        <IonTabButton tab="tab1" href="/home">
                            <IonIcon aria-hidden="true" icon={home} />
                            <IonLabel>Home</IonLabel>
                        </IonTabButton>
                        {/* <IonTabButton tab="schedules" href="/schedules">
                            <IonIcon aria-hidden="true" icon={calendar} />
                            <IonLabel>Schedules</IonLabel>
                        </IonTabButton>
                        <IonTabButton tab="automations" href="/automations">
                            <IonIcon aria-hidden="true" icon={cog} />
                            <IonLabel>Automations</IonLabel>
                        </IonTabButton> */}
                        <IonTabButton tab="notifications" href="/notifications">
                            <IonIcon aria-hidden="true" icon={bellIcon} />
                            <IonLabel>Notifications</IonLabel>
                        </IonTabButton>
                        <IonTabButton tab="menu" href="/menu">
                            <IonIcon aria-hidden="true" icon={menu} />
                            <IonLabel>Menu</IonLabel>
                        </IonTabButton>
                    </IonTabBar>
                </IonTabs>
            </IonReactRouter>
            </>
            )
        }
        <IonLoading
            mode='ios'
            isOpen={showLoadingState.showLoading}
            onDidDismiss={() => setShowLoading({...showLoadingState, showLoading: false})}
            message={showLoadingState.showLoadingMessage}
        />
        <IonAlert
            mode='ios'
            isOpen={showAlertState.showAlert}
            onDidDismiss={() => setShowAlertState({...showAlertState, showAlert: false})}
            header={showAlertState.header}
            subHeader={showAlertState.subHeader}
            message={showAlertState.message}
            inputs={showAlertState.inputs}
            buttons={showAlertState.buttons}
        />
    </IonApp>
}
export default App;