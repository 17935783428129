import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from "@ionic/react"

import './index.css';
const Schedules:React.FC = ()=>{

    return <IonPage>
        <IonHeader>
            <IonToolbar>
                <IonTitle>
                    Schedules
                </IonTitle>
            </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
            <IonHeader collapse="condense">
                <IonToolbar>
                    <IonTitle size="large">Schedules</IonTitle>
                </IonToolbar>
            </IonHeader>
            
        </IonContent>
    </IonPage>
}
export default Schedules;