import { IonButton, IonIcon, IonInput, IonItem, IonLabel } from '@ionic/react';
import { lockClosed, person } from 'ionicons/icons';
import React from 'react'

import './index.css';

const  Login:React.FC<any> = ({routerFunction, fowardOnsubmit, formEntries}) => {
    return (
        <div className="topDiv">
            <br/>
            <form method="POST" action="api/authentication?logIn=1&mod=0" onSubmit={fowardOnsubmit}>
                <input type="hidden" name="in_App" defaultValue="iot" />
                <input type="hidden" name="type" defaultValue="login" />
                <IonItem className="ionMyItem" mode='ios'>
                    <IonLabel position="floating" className="theLabel" >Email / Phone</IonLabel>
                    <IonIcon mode='ios' className="iconPart" icon={person} />
                    <IonInput name="in_AppimateID" type="text" className="theInput" value={(formEntries&&formEntries['in_AppimateID'])?(formEntries['in_AppimateID']):("")} required />
                </IonItem>
                <IonItem className="ionMyItem" mode='ios'> 
                    <IonLabel position="floating" className="theLabel" >Password</IonLabel>
                    <IonIcon mode='ios' className="iconPart" icon={lockClosed} />
                    <IonInput name="in_Password" type="password" className="theInput" value={(formEntries&&formEntries['in_Password'])?(formEntries['in_Password']):("")} required />
                </IonItem>
                <br/>
                <br/>
                <IonButton mode='ios' expand="block" type="submit" className="ion-activatable ripple-parent subButton">Sign in</IonButton>
            </form>
            <br/>
            <br/>
            <br/>
            <div className="myButtons">                                            
                <p className="myForgot" onClick={()=>routerFunction('forgotPassword')}>Forgot password?  </p> 

                <p className="myForgot" onClick={()=>routerFunction('register')}>Don't have an account?  <span className='mySpan'> Sign up now.</span></p>           
            </div>
        </div>
    );
}

export default Login;