import { useEffect, useState } from "react";
import { useIonToast } from "@ionic/react";

import { constraints, getUserMedia } from "../../Services/utils";
import QrScanner from 'qr-scanner'; // if installed via package and bundling with a module bundler like webpack or rollup

import './index.css';
const QrScannerComponent:React.FC<any> = ({className, scanRate, onError, onScan})=>{
    const [qrOpen, setQRopen] = useState(false);
    const [currentStreams, setCurrentSteams] = useState<any>([]);
    const [present, dismiss] = useIonToast();

    useEffect(()=>{
        if ( !qrOpen && currentStreams.length === 0) {
            var adaptiveConstraints: any = constraints;
            getUserMedia('sensors', adaptiveConstraints).then((result: any)=>{
                if (result.success) {
                    setQRopen(true);
                    let theVideo:any = document.getElementById('qrVideo');
                    // let qrCanvas:any = document.getElementById('qrCanvas');
                    let qrScanner = new QrScanner(
                        theVideo, 
                        (result:any)=>{ onScan(result)},
                        {
                            preferredCamera: constraints.video.facingMode, 
                            maxScansPerSecond: scanRate, 
                            highlightScanRegion: true, highlightCodeOutline: true,
                            // overlay: uiOverlay, 
                            // canvas: qrCanvas,
                            onDecodeError: onError
                        }
                    );
                    qrScanner.start();
                    setCurrentSteams((s:any)=>([...s, qrScanner]));
                } else {
                    present({
                        mode: 'ios',
                        position: "top",
                        buttons: [{ text: 'cancel', handler: () =>{

                        } }],
                        message: `Couldn't Access Cameara`,
                        duration: 2000
                    });
                }
            })
        };
        return ()=>{
            currentStreams.forEach((eachStream: any)=>{
                if (eachStream) eachStream.stop();
            });
            // setQRopen(false);
        }
    }, [qrOpen, scanRate, currentStreams, onError, onScan, present, dismiss]);

    return (
        <div className={`qrHolder ${className}`}>
            <canvas id="qrCanvas"></canvas>
            <video  id="qrVideo"></video>
            <div id="qrOverlay"></div>
        </div>
    )
}
export default QrScannerComponent;