import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getDatabase } from "firebase/database";
import { getAnalytics } from "firebase/analytics";

// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object
const firebaseConfig = {
    apiKey: "AIzaSyAp7QeJs7hDbL4u9o0NRLfOEXz1kkABAjU",
    authDomain: "iotbase-d9d2c.firebaseapp.com",
    databaseURL: "https://iotbase-d9d2c-default-rtdb.firebaseio.com",
    projectId: "iotbase-d9d2c",
    storageBucket: "iotbase-d9d2c.appspot.com",
    messagingSenderId: "587162867339",
    appId: "1:587162867339:web:068f2deada64b835ab268e",
    measurementId: "G-WSMBCDQN30"
};
const app = initializeApp(firebaseConfig);

// Initialize Cloud Firestore and get a reference to the service
const cloudFireStoreDB = getFirestore(app);
const realtimeDb = getDatabase(app);
const analytics = getAnalytics(app);

export {cloudFireStoreDB, realtimeDb, analytics};