import { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation} from 'react-router';

import { 
    IonAvatar, IonContent, IonHeader, IonInfiniteScroll, 
    IonInfiniteScrollContent, IonItem, 
    IonLabel, IonList, IonPage, IonRefresher, IonRefresherContent, 
    IonSearchbar, IonTitle, 
    IonToolbar, RefresherEventDetail, useIonToast 
} from '@ionic/react';
import { chevronDownCircleOutline } from 'ionicons/icons';

import { AppContext } from '../../contexts/AppContextProvider';
import { appAuthDomain, makeRequests } from '../../Services/utils';

import './index.css';
import { setNotifyCounter } from '../../Services/State';

  

const Notifications:React.FC<any> = ({doPlay})=>{
    const { state, dispatch } = useContext(AppContext);

    const [firstLoaded, setFirstLoaded] = useState(false);
    const [data, setData] = useState<any>([]);
    const [isInfiniteDisabled, setInfiniteDisabled] = useState(false);
    const [present, dismiss] = useIonToast();

    const onClick = (item: any)=>{
        if("type" in item){
            
        };
        var reqObj = {method: "POST", url: appAuthDomain('api/requests'), data: { about:'notifications',  open: 1, notifyID: item.id }};
        makeRequests(state, reqObj).then(response=>{
            dispatch(setNotifyCounter(-1));
        });
    }
    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        const callbackFunct = ()=>{
            event.detail.complete();
        }
        pushData(callbackFunct, 1);
    }
    const pushData = useCallback((callback: Function, direction: number) => {
        var lastNotifyID:number = 0;
        if (data.length > 0) {
            var oldestFound = data[data.length - 1];
            var lastNofification = data[0];
            if (typeof lastNofification === 'object') {
                if ('id' in lastNofification) {
                    if (direction < 0) {
                        lastNotifyID = oldestFound['id'];
                    } else {
                        lastNotifyID = lastNofification['id'];
                    }
                }
            }
        };
        var requestObj = {
            method: "GET", 
            url: appAuthDomain('api/notifications?appType=iot'), 
            data: {
                fetching: 1, 
                lastNotifyID, 
                direction,
            }
        };
        makeRequests(state, requestObj).then(response=>{
            callback();
            if (response.success) {
                var newData = response.data;
                setData([
                    ...data,
                    ...newData
                ]);
            } else {
                let newDataTest:any = [
                    // {id: 1, profilePic: "assets/icon/ICON 144x144.png", head: "kkklk", body: "yyy", driver:1, type:"rate"},
                    // {id: 1, profilePic: "assets/icon/ICON 144x144.png", head: "xxx", body: "yyy"},
                    // {id: 2, profilePic: "assets/icon/ICON 144x144.png", head: "xxx", body: "yyy"},
                    // {id: 3, profilePic: "assets/icon/ICON 144x144.png", head: "xxx", body: "yyy"},
                    // {id: 4, profilePic: "assets/icon/ICON 144x144.png", head: "xxx", body: "yyy"},
                ];
                setData([
                    ...data,
                    ...newDataTest
                ]);
                present({
                    mode: 'ios',
                    position: "bottom",
                    buttons: [{ text: 'hide', handler: () => dismiss() }],
                    message: response.msg,
                    duration: 2000
                });
            }
        })
    }, [state, data, present, dismiss]);
    const loadData = (ev: any, direction: number) => {
        setTimeout(() => {
            const callbackFunct = ()=>{
                ev.target.complete();
            };
            pushData(callbackFunct, direction);            
            if (data.length === 1000) {
                setInfiniteDisabled(true);
            };
        }, 500);
    }
    const location = useLocation();
    const locationState = (location.pathname).substring(1, (location.pathname).length);
    useEffect(()=>{
        if (state.auth.user) {
            if (!firstLoaded) {
                setFirstLoaded(true);
                pushData(()=>{}, -1);
            }
        }
        var tabBar = document.getElementById("navigationTabBarRef");
        if(tabBar){
            if (locationState === "notify") {
                tabBar.style.transform = "";
                tabBar.style.display = `flex`;
            }
        }
    }, [state.auth.user, firstLoaded, pushData, locationState]);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Notifications</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">Notifications</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonSearchbar mode='ios' />
                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent
                        pullingIcon={chevronDownCircleOutline}
                        pullingText="Pull to refresh"
                        refreshingSpinner="circles"
                        refreshingText="Refreshing...">
                    </IonRefresherContent>
                </IonRefresher>

                <IonList>
                    {
                        data.map((item: any, key: number) => {
                            return (
                                <IonItem key={key} onClick={()=>{onClick(item)}} className="notificationItem">
                                    <IonAvatar>
                                        <img src={appAuthDomain(item.profilePic)} alt="profilePic"/>
                                    </IonAvatar>
                                    <IonLabel className='notifyListtext'>
                                        <h4>
                                            {item.firstname+" "+item.lastname}
                                        </h4>
                                        <p>
                                            {item.head}
                                        </p>
                                        <p>
                                            {item.body}
                                        </p>
                                    </IonLabel>                                    
                                </IonItem>
                            )
                        })
                    }
                </IonList>
                <IonInfiniteScroll
                onIonInfinite={(evt)=>{loadData(evt, -1)}}
                threshold="100px"
                disabled={isInfiniteDisabled}
                >
                    <IonInfiniteScrollContent
                        loadingSpinner="bubbles"
                        loadingText="Loading more notifications..."
                    ></IonInfiniteScrollContent>
                </IonInfiniteScroll>
            </IonContent>
        </IonPage>
    );
}

export default Notifications;