import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { IonButton, IonButtons, IonCard, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonModal, IonPage, IonSegment, IonSegmentButton, IonSkeletonText, IonTitle, IonToolbar, useIonViewWillEnter } from "@ionic/react";
// import { AppContext } from "../../contexts/AppContextProvider";
// import { localDomain, makeRequests } from "../../Services/utils";

import Charts from "../../components/Charts";
import Insights from "../../components/Insights";
import Configuration from "../../components/Configuration";

import { 
    collection, 
    // limit, 
    onSnapshot, orderBy, query, where, 
} from 'firebase/firestore';
import { query as rtdbQuery, onValue, ref, limitToLast } from 'firebase/database';
import { cloudFireStoreDB, realtimeDb } from '../../Services/firebase-config';


import './index.css';

import {barChart, telescope, addCircle, radioButtonOff, thermometer, flask } from "ionicons/icons";
import { getUserID } from "../../Services/State";
import { AppContext } from "../../contexts/AppContextProvider";
// import { Capacitor } from '@capacitor/core';
import { appAuthDomain, generateHandle, getTimeOfDay, makeRequests } from "../../Services/utils";
interface Reading {
    pH: string;
    temperature: string;
    timestamp: string;
}
const Home:React.FC<any> = ({routerRef, setShowLoading, setShowAlertState})=>{
    const { state } = useContext(AppContext);
    const getDateTimeObject = getTimeOfDay(new Date());
    const ionListRef:any = useRef();
    const [isLoading, setIsLoading] = useState(false);
    const [segment, setSegment] = useState("insights");
    const [deviceReading, setDeviceReading] = useState<any>([])
    // const [valuesMonitored, setValuesMonitored] = useState(false);
    const [theGraphData, setTheGraphData] = useState<any>({temp: [], PH:[], labels:[]});
    const [showModal, setShowModal] = useState<any>({content: [], header:"", show: false});

    const [devices, setDevices] = useState<any>([{type: 'loader', identifier:[]}, {type: 'loader', identifier:[]}, {type: 'loader', identifier:[]}, {type: 'loader', identifier:[]}]);
    const [chartData, setChartData] = useState<any>([
        {type: 'bar', labels: ['tank 1', 'tank 2', 'tank 3', 'tank 4', 'tank 5', 'tank 6', 'tank 7'], datasets: [
            {label: "Temp", data: [3, 7, 11, 9, 15, 19, 21]},
            {label: "PH", data: [4, 7, 1, 3, 5, 5, 8]},
        ], scale: {yAxes: {ticks: {beginAtZero: true}}}},
        {type: 'line', labels: ['tank 1', 'tank 2', 'tank 3', 'tank 4', 'tank 5', 'tank 6', 'tank 7'], datasets: [
            {label: "Temp", data: [3, 7, 11, 9, 15, 19, 21]},
            {label: "PH", data: [4, 7, 1, 3, 5, 5, 8]},
        ], scale: {yAxes: {ticks: {beginAtZero: true}}}},
        // {type: 'doughnut', labels: ['t', 'u', 'v', 'w', 'x', 'y', 'z'], datasets: [
        //     {label: "Requests", data: [1, 5, 3, 7]}, 
        // ]},
        // {type: 'polarArea', labels: ['t', 'u', 'v', 'w', 'x', 'y', 'z'], datasets: [
        //     {label: "Earnings", data: [1, 5, 3, 4, 8]}, 
        // ]},
    ]);

    const onSegment = (seg: any)=>{
        setSegment(seg);
    }
    useIonViewWillEnter(() => {
        const bottomNav = document.getElementById("navigationTabBarRef");
        if (bottomNav) {
            bottomNav.style.display = "flex";
        };
        if (!isLoading) {
            setIsLoading(true);
        }
    });

    const fetchReadings = useCallback(async (deviceIdentifier:any, howMany:any)=>{
        let data:any = [];
        if (deviceIdentifier.identifier.identifier) {
            var deviceDataFolder = (deviceIdentifier.identifier.identifier).replaceAll(".", "-");
            let topUserPostsRef = rtdbQuery(ref(realtimeDb, `devices/${deviceDataFolder}/readings`), limitToLast(1));
            if(howMany === "One"){
                topUserPostsRef = rtdbQuery(ref(realtimeDb, `devices/${deviceDataFolder}/readings`), limitToLast(5));
            }
            onValue(topUserPostsRef, (snapshot)=>{
                data = snapshot.val();
                let formartedDeviceData = Object.values(data);
                if(howMany === "One" || howMany === "graphs"){
                    const tempData:any = [];
                    const phData:any = [];
                    const times:any = [];
                    const isReading = (item: any): item is Reading => {
                        return (
                          typeof item === "object" &&
                          (typeof item.pH === "string" || typeof item.pH === 'number') &&
                          (typeof item.temperature === "string" || typeof item.temperature === 'number') &&
                          (typeof item.timestamp === "string" || typeof item.timestamp === "number")
                        );
                    };
                    
                    for (const item of formartedDeviceData) {
                        if (isReading(item)) {
                          const pHValue: number = parseFloat(item.pH);
                          const tempValue: number = parseFloat(item.temperature);
                          const timestampValue: number = parseInt(item.timestamp) * 1000;
                          const date = new Date(timestampValue);
                          const formattedTime = date.toLocaleTimeString();
                          phData.push(pHValue);
                          tempData.push(tempValue); times.push(formattedTime);
                        }
                    }
                    if( howMany === "graphs"){
                        if(deviceIdentifier.identifier.identifier){
                            if(tempData.length> 0){
                                setTheGraphData((prevData: { temp: any; PH: any; labels: any; }) => {
                                    const isLabelExists = prevData.labels.includes(deviceIdentifier.identifier.name)
                                    if(!isLabelExists){
                                        let newGraphData = ({
                                            temp: [...prevData.temp, tempData[0]],
                                            PH: [...prevData.PH, phData[0]],
                                            labels: [...prevData.labels, deviceIdentifier.identifier.name],
                                        })
                                        const newChartData = [
                                            {type: 'bar', labels: newGraphData.labels, datasets: [
                                                { label: 'Temp', data: newGraphData.temp }
                                            ], scale: {yAxes: {ticks: {beginAtZero: true}}}},
                                            {type: 'line', labels: newGraphData.labels, datasets: [
                                                 { label: 'PH', data: newGraphData.PH }
                                            ], scale: {yAxes: {ticks: {beginAtZero: true}}}}, 
                                        ];
                                        setChartData(newChartData);
                                        
                                        return newGraphData
                                    }else{
                                        return prevData 
                                    }
                                });
                                
                                
                            }
                        }

                        
                    }else{
                        setDeviceReading(
                            [
                                {type: 'bar', labels: times, datasets: [
                                    { label: 'Temp', data: tempData }
                                ], scale: {yAxes: {ticks: {beginAtZero: true}}}},
                                {type: 'line', labels: times, datasets: [
                                    { label: 'PH', data: phData }
                                ], scale: {yAxes: {ticks: {beginAtZero: true}}}},
                            ]
                        )
                    }
                }else{
                    setDevices((devices:any)=>{
                        devices = devices.map((ed:any)=>{
                            if (ed.identifier.identifier === deviceIdentifier.identifier.identifier) {
                                ed.readings = formartedDeviceData;
                            };
                            return ed;
                        })
                        return [...devices];
                    })
                    // setValuesMonitored(true);
                }
                
            });
        }
    },[])


    const fetchDevices = useCallback(()=>{
        if (state.auth.user) {
            var myDevices:any = [];
            setShowLoading((s:any)=>({...s, showLoadingMessage: "Fetching devices...", showLoading: true, triggered: true}));
            var ownerID = parseInt(state.auth.user.parentAccount)|| getUserID(state);
            var collectionRef = collection(cloudFireStoreDB, "devices");
            var q = query(
                collectionRef, 
                where("ownerID", "==", ownerID),
                orderBy("timeAdded", "asc"), 
                // limit(3)
            );
            onSnapshot(q, (snapshot) => {
                    snapshot.docChanges().forEach(async (change) => {
                    var docID =  change.doc.id;
                    var docData =  change.doc.data();
                    docData.id = docID;
                    docData.timeAdded = docData.timeAdded.toDate().toDateString();
                    myDevices.push(docData);
                })
                setDevices(myDevices)
                // setGotDevices(true);

                setTimeout(() => {
                    setShowLoading((s:any)=>({...s, showLoading: false}));
                }, 500);
            }, (err)=>{
                console.log(err);
            });
        }
    },[state, setShowLoading]);

    const forEachDevice = useCallback(async (eachDevice:any)=>{
        await fetchReadings(eachDevice, 'One');
        setShowModal((s:any)=>({...s, content: "", header: eachDevice.identifier.name, show: true}));
    },[fetchReadings]);


    useEffect(()=>{
        if (state.auth.user) {
            if(segment === "devices" || segment ===  'insights'){
                fetchDevices();                
            }
        }
        
    },[state.auth.user, segment, fetchDevices])

    useEffect(()=>{
        if(segment === 'insights'){
            if(devices && (devices.length > 0)){
                for (const eachDevice of devices) {
                    fetchReadings(eachDevice, 'graphs');
                }
            }else if(devices.length < 1){
                setChartData([])
            }
        }else if(segment === 'devices'){
            // if(devices && (devices.length > 0) && !valuesMonitored){
            if(devices && (devices.length > 0)){
                for (const eachDevice of devices) {
                    if(!eachDevice.readings){
                        fetchReadings(eachDevice, 'many');
                    }
                }
            }
        }
    }, [devices,segment, fetchReadings]);

    // const makeHandle = (name: any)=>{
        // setMyHandle(generateHandle(name));
    // }

    const submitBusiness= async (preparedQrDdata: any)=>{
        console.log(preparedQrDdata);
        var alertStateVarsSuccess = {};
        setShowAlertState((showAlert:any)=>({...showAlert, ...alertStateVarsSuccess, showAlert: false}));
        setShowLoading((s:any)=>({...s, showLoadingMessage: "Submiting...", showLoading: true, triggered: true}));
        var headers = {
            "Content-Type": "multipart/form-data"
        };
        var url = appAuthDomain("api/adminStream?appType=trans&action=addGroup");
        var requestObj = {method: "POST", url: url, headers: headers, data: preparedQrDdata};
        makeRequests(state, requestObj).then(response=>{
            console.log(response)
            setTimeout(() => {
                setShowLoading((s:any)=>({...s, showLoading: false}));
            }, 500);
            var buttonAction:any = [];
            if (response.success) {
                buttonAction = [
                    {
                        text: 'Done',
                        handler: () => {}
                    }
                ];
            }else{
                buttonAction = [
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            // window.history.back();
                        }
                    },
                    {
                        text: 'Retry',
                        handler: () => {
                            submitBusiness(preparedQrDdata);
                        }
                    }
                ];
                
            }

            alertStateVarsSuccess = {
                header:response.msg, subHeader: "", message: response.msg2,inputs:[], buttons: buttonAction
            }
            setShowAlertState((showAlert:any)=>({...showAlert, ...alertStateVarsSuccess, showAlert: true}));
        })

    }

    const addBusiness = ()=>{
        var ownerID = parseInt(state.auth.user.parentAccount)|| getUserID(state);
        var alertStateVarsSuccess = {
            header: "Add organization", subHeader: "", message: "Enter these mandatory fields", 
            inputs: [
                {name: 'in_GroupType', value:'4', type: 'hidden', required: true},
                {name: 'in_Owner', value:ownerID, type: 'hidden', required: true},
                {name: 'in_GroupCategory', value:"iot", type: 'hidden', required: true},
                {name: 'in_GroupName', label: 'Organization name', placeholder: 'Organization name', type: 'text', required: true},
                {name: 'in_AddressGroup', label: 'Location', placeholder: 'Physical address', type: 'text', require: true},
                {name: 'in_EmailGroup', label: 'Email', placeholder: 'Email address', type: 'email', require: true},
                {name: 'in_PhoneGroup', label: 'Phone', placeholder: 'Phone number', type: 'text', require: true},
                // {name: 'identifier', label: 'Device ID', placeholder: 'Device ID', type: 'text', require: true},
            ], 
            buttons: [
                { text: 'Submit', handler: (inputData:any) => { 
                    if ((inputData.in_GroupName === "")||(inputData.in_GroupName === "")) {
                        setTimeout(() => {
                            addBusiness();
                        }, 300);
                    } else {
                        inputData.in_Handle = generateHandle(inputData.in_GroupName);
                        submitBusiness(inputData);
                    }
                }
            }
        ]};
        setShowAlertState((showAlert:any)=>({...showAlert, ...alertStateVarsSuccess, showAlert: true}));
    }

    const isMobile = window.innerWidth < 600 && /Android|iPad/i.test(navigator.userAgent);
    // const isTabletOrIpad = navigator.maxTouchPoints > 1;
    console.log(theGraphData)
    console.log(state)
    
    return <IonPage>
        {(isMobile || (window.innerWidth < 600))?(
            <>
            <IonHeader mode="ios">
                <IonToolbar>
                    <IonTitle>
                        Home
                    </IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonHeader mode="ios" collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">Home</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <br/>
                <IonCard>
                    <Charts />
                </IonCard>
                <IonSegment mode='ios' value={segment} onIonChange={e => onSegment(e.detail.value!)}>
                    <IonSegmentButton value={'insights'}>
                        <IonLabel>Insights </IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value={'configure'}>
                        <IonLabel>+ Configure </IonLabel>
                    </IonSegmentButton>
                </IonSegment>
                {
                    (segment === "insights")?(
                        <Insights routerRef={routerRef} onSegment={onSegment} setShowLoading={setShowLoading} setShowAlertState={setShowAlertState} />
                    ):(
                        <Configuration routerRef={routerRef} onSegment={onSegment} setShowLoading={setShowLoading} setShowAlertState={setShowAlertState} />
                    )
                }
            </IonContent>
            </>
        ):(
        <div className='backgroundDiv'>
            
            <div className='leftSide'>
                <div className='dashNameHolder'>
                    <div className='theIMG'>
                        <img alt='' src='assets/icons/icon-48.webp'/>
                    </div>
                    <h3>Dashboard</h3>
                </div>
                <br/>
                <br/>
                <IonItem className='sectionHolder' onClick={()=>setSegment("insights")} button mode='ios'>
                    <IonIcon  icon={barChart} slot="start"  className='myIcons'/>
                    <IonLabel>Insights</IonLabel>
                </IonItem>
                <IonItem className='sectionHolder' onClick={()=>setSegment("devices")} button mode='ios'>
                    <IonIcon  icon={telescope} slot="start"  className='myIcons'/>
                    <IonLabel>View devices</IonLabel>
                </IonItem>
                <IonItem className='sectionHolder' onClick={()=>setSegment("addDevice")} button mode='ios'>
                    <IonIcon  icon={addCircle} slot="start" className='myIcons'/>
                    <IonLabel>Add device</IonLabel>
                </IonItem>
                <IonItem className='sectionHolder' onClick={()=>setSegment("addOrg")} button mode='ios'>
                    <IonIcon  icon={addCircle} slot="start" className='myIcons'/>
                    <IonLabel>Add organisation</IonLabel>
                </IonItem>
            </div>
            <div className='rightSide'>
                <div className='headerTop'><h2>
                {
                    (state.auth.user)?(
                        "Good "+getDateTimeObject.name+" "+state.auth.user.firstname
                    ):(
                        "Good "+getDateTimeObject.name
                    )
                }
                </h2></div>
                <div className='bodyDiv'>
                {(segment === 'insights')?(
                    (chartData.length > 0)?(
                        chartData.map((theData: any)=>{
                            return <IonCard className='myCard' key={theData.type}>
                                <Charts data={theData} />
                            </IonCard>;
                        })
                    ):(
                        <p style={{textAlign: "center"}}>You do not have any connected devices</p>    
                    )
                    
                ):(
                    (segment === 'addDevice')?(
                        <Configuration routerRef={routerRef} onSegment={onSegment} setShowLoading={setShowLoading} setShowAlertState={setShowAlertState} isDesktop="Yes"/>
                    ):(
                        (segment === 'addOrg')?(
                            <>
                            <div className="deviceConfigView">
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <div className="addWiFiButtonHolder">
                                    <IonButton size="small" className="addWiFiButton" onClick={addBusiness}>Add Organisation <IonIcon className="btnIcon" icon={addCircle}/> </IonButton>
                                </div>
                            </div>
                            </>):(
                            (devices.length > 0)?(
                                devices.map((eachDevice: any, key:number)=>{
                                    return (eachDevice.type === 'loader')?(
                                        <IonCard key={key} mode='ios' className={'deviceContainer'} >
                                            <p className="msgPart"><IonSkeletonText /></p>
                                            <p className="valuePart">
                                                <IonSkeletonText />
                                            </p>
                                            <IonIcon className="iconPart" icon={radioButtonOff}/>
                                            <p className="unitsPart"> <IonSkeletonText /> </p>
                                        </IonCard>
                                    ):(
                                        <IonCard key={key} onClick={()=>forEachDevice(eachDevice)} mode='ios' className='myDeviceContainer'>
                                            <h2 className="theDeviceHolder">{eachDevice.identifier.name}</h2>
                                            <div className="infoHolder">
                                                <p>Temp: {(eachDevice.readings)?(eachDevice.readings[0].temperature):("0")}</p>
                                                <IonIcon className="iconPart" icon={thermometer}/>
                                            </div>
                                            <div className="infoHolder">
                                                <p>PH: {(eachDevice.readings)?(eachDevice.readings[0].pH):("0")}</p>
                                                <IonIcon className="iconPart" icon={flask}/>
                                            </div>
                                        </IonCard>
                                    )
                                })
                            ):(
                                <p style={{textAlign: "center"}}>You do not have any connected devices</p>                        
                            )
                        )
                    )
                )}
                </div>
                
            </div>
        </div>
        )}
        {/* <div className="forMobile">
        
        </div> */}
        <IonModal 
            mode='ios'
            isOpen={showModal.show}
            // presentingElement={myPresentingElemRef?.current}
            canDismiss={true}
            className="modalPop"
            onDidDismiss={() => setShowModal((s:any)=>{ionListRef?.current?.closeSlidingItems(); return {...s, stage: "qrCodeScan", show: false}})}
        >
            <IonHeader >
                <IonToolbar className="modalPopHeader-tool">
                    <IonButtons slot="end">
                        <IonButton onClick={() => setShowModal((s:any)=>{ionListRef?.current?.closeSlidingItems(); return {...s, stage: "qrCodeScan", show: false};})}>Close</IonButton>
                    </IonButtons>
                    <IonTitle> {showModal.header}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="modalPopContent">
                {
                   (deviceReading.length> 0)?(
                        deviceReading.map((theData: any)=>{
                            return<IonCard className='chart-container2' key={theData.type}>
                                    <Charts data={theData} />
                                </IonCard>
                        })
                   ):(<p>No Data</p>)
                }
            </IonContent>
            
        </IonModal>
    </IonPage>
}
export default Home;