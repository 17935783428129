import { useEffect, useState } from "react";
import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonModal, IonTitle, IonToolbar } from "@ionic/react"
import { arrowDown, reload } from "ionicons/icons";
import QrScannerComponet from "../QrScannerComponent";


import './index.css';
const ScanQrCode:React.FC<any> = ({myPresentingElemRef, isOpen, setIsDeviceLinkageOpen, handlScanResults})=>{
    const [result, setResult] = useState("");

    const scanRate = 25;
    const reTryScan = ()=>{
        setResult("");
        setIsDeviceLinkageOpen(true);
    }
    const handleScan = (result: any) => {
        console.log(result);
        if (result) {
            setResult(result.data);
            handlScanResults(result);
        }
    };
    const handleError = (error: any) => {
        console.log(error);
    };
    useEffect(()=>{
        if (isOpen && (result !== "")) {
            setIsDeviceLinkageOpen(false);
        };
    }, [isOpen, result, setIsDeviceLinkageOpen]);

    return (
        <IonModal
            mode="ios"
            presentingElement={myPresentingElemRef?.current}
            isOpen={isOpen}
            canDismiss={true}
            onDidDismiss={() => setIsDeviceLinkageOpen(false)}
        >
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonButton fill="clear" onClick={()=>{setIsDeviceLinkageOpen(false)}}>
                            <IonIcon icon={arrowDown} />close
                        </IonButton>
                    </IonButtons>
                    <IonTitle>
                        Link Device with App.
                    </IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                {
                    (isOpen)?(
                        <QrScannerComponet
                            // isOpen={isOpen}
                            scanRate={scanRate}
                            // style={previewStyle}
                            onError={handleError}
                            onScan={handleScan}
                        />
                    ):("")
                }
                {
                    (result !== "")?(
                        <>
                        <div className="qrMessage">
                            {result}
                        </div>
                        <IonButton mode="ios" expand="block" onClick={reTryScan}>
                            <IonIcon icon={reload}/>
                            Try Again
                        </IonButton>
                        </>
                    ):(
                        <div className="qrMessage">
                            Looking for QR codes...
                        </div>
                    )
                }
            </IonContent>
        </IonModal>
    )
}
export default ScanQrCode;