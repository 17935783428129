import axios from 'axios';
// import { NativeBiometric, BiometryType, AvailableResult } from "capacitor-native-biometric";
import { Capacitor } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';
// import { Geolocation } from '@capacitor/geolocation';
import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { PushNotificationSchema, PushNotifications, Token, ActionPerformed } from '@capacitor/push-notifications';
import { flask, heart, thermometer } from 'ionicons/icons';



export const three_line = /\n\n\n/g;
export const two_line = /\n\n/g;
export const one_line = /\n/g;
export const first_char = /\S/;

export interface ShowAlertInterface {
    header: string;
    subHeader: string;
    message: string;
    buttons?: any;
    inputs?: any;
    showAlert: boolean;
}

export const applicationServerPublicKey = "BFcrXzGXv3UrzWTvrbYgs27tCy_y5OO9FTcvh6ND-zug_jFgOjXcPvEdG_FDO0ZLXeT0nJpIQhKi7PigTtrVRc0";

export const supportedInsights:any = {
    pH: {name: "pH", code: "pH", icon: flask, classLevel: ['Gr10','Gr11','Gr12','Yr1','Yr2'], colorClass: "chemColor"},
    heartRate: {name: "Heart rate", code: "hRate", icon: heart, classLevel: ['Gr10','Gr11','Gr12','Yr1','Yr2'], colorClass: "bioColor"},
    temperature: {name: "Temperature", code: "temp", icon: thermometer, classLevel: ['Gr10','Gr11','Gr12','Yr1','Yr2'], colorClass: "bioColor"},
};
export const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ];
export const videoConstraints = {facingMode: 'environment'};
export const constraints = {video: videoConstraints, audio: false};
export const timeSimpleNameList = ['morning', 'day', 'afternoon', 'evening'];
export const getTimeOfDay = (time: any)=>{
    var secs = time.getSeconds(), mins = time.getMinutes(), hh = time.getHours();
    
    var ttName = "";
    if (hh < 12) {
        ttName = timeSimpleNameList[0];
    } else if ((hh > 11) && (hh <= 13)) {
        ttName = timeSimpleNameList[1];
    } else if ((hh > 12) && (hh <= 17)) {
        ttName = timeSimpleNameList[2];
    } else if (hh > 17) {
        ttName = timeSimpleNameList[3];
    };
    return {name: ttName, hour: hh, min: mins, sec: secs};
}
/*=================================Offline Protocol settings==============================*/
// const SOCKET_PROTOCOL = "ws";
// const HTTP_PROTOCOL = "http";
// const APP_LOCAL_DOMAIN = "localhost:3002";
// const APP_AUTH_DOMAIN = "localhost:3000";
/*=================================Online Protocol settings==============================*/
const SOCKET_PROTOCOL = "wss";
const HTTP_PROTOCOL = "https";
const APP_LOCAL_DOMAIN = "iot.appimate.com";
const APP_AUTH_DOMAIN = "appimate.com";


export const currentWebSocketDomain = (path: any) => `${SOCKET_PROTOCOL}://${APP_LOCAL_DOMAIN}/${path}`;
export const localDomain = (path: any) => `${HTTP_PROTOCOL}://${APP_LOCAL_DOMAIN}/${path}`;
export const appAuthDomain = (path: any) => `${HTTP_PROTOCOL}://${APP_AUTH_DOMAIN}/${path}`;
export const imgProfiles = (path: any) => `${HTTP_PROTOCOL}://${APP_AUTH_DOMAIN}/${path}`;
export const quizSourceDomain = (path: any) => `${HTTP_PROTOCOL}://${APP_AUTH_DOMAIN}/${path}`;
export const videosSourceDomain = (path: any) => `${HTTP_PROTOCOL}://${APP_AUTH_DOMAIN}/${path}`;
export const img = (path: string) => `${path}`;

export const sleep = (n: any) => new Promise(r => setTimeout(r, n));


export const msToTime = (d: any) => {
    var seconds = Math.floor((d / 1000) % 60), minutes = Math.floor((d / (1000 * 60)) % 60);

    return minutes + ":" + (seconds < 10 ? `0${seconds}` : seconds);
}

export const dateTimeNow = ()=> {
    var today = new Date(), secs:any = today.getSeconds(), mins:any = today.getMinutes(), hh:any = today.getHours(), dd:any = today.getDate(), mm:any = today.getMonth()+1,/*January is zero*/ yyyy:any = today.getFullYear();
    if (dd < 10) {dd = '0'+dd;}; if (mm < 10) {mm = '0'+mm;};
    if (secs < 10) {secs = '0'+secs;}; if (mins < 10) {mins = '0'+mins;};
    if (hh < 10) {hh = '0'+hh;};
    return yyyy+'-'+mm+'-'+dd+' '+hh+':'+mins+':'+secs;
}
export const dateTimeFromSeconds= (seconds:number)=> {
    var today = new Date(seconds), secs:any = today.getSeconds(), mins:any = today.getMinutes(), hh:any = today.getHours(), mmNub=today.getMonth(), dd:any = today.getDate(), mm:any = today.getMonth()+1/*January is zero*/;
    if (dd < 10) {dd = '0'+dd;}; 
    if (mm < 10) {mm = '0'+mm;};
    if (secs < 10) {secs = '0'+secs;}; if (mins < 10) {mins = '0'+mins;};
    if (hh < 10) {hh = '0'+hh;};
    return dd+'-'+monthNames[mmNub]+' '+hh+':'+mins;
}
export const formatDateTime = (date: string, time: string) => {
    var dateTime="", msgDate = date.split("-"), msgTime:any;
    if (time!==undefined) {
        msgTime = time.split(":");
    };
    var now = new Date(), dd:any = now.getDate(), mm:any = now.getMonth()+1/*, yyyy = now.getFullYear()*/;
    if (dd < 10) {dd = '0'+dd;};
    if (mm < 10) {mm = '0'+mm;};
    if( parseInt(msgDate[2]) === parseInt(dd) ) {
        if (time!==undefined) {
            if ((((msgTime[2]).substring(2,4) === 'pm')||(parseInt(msgTime[2].substring(2,4))>12))&&(parseInt(msgTime[0]) - 12 > 0 )){
                dateTime+=(parseInt(msgTime[0])+12)+":"+msgTime[1];
            }else{
                dateTime+=msgTime[0]+":"+msgTime[1];
            };
        }; 
        dateTime+=" Today";
    } else if ( (parseInt(msgDate[2])<parseInt(dd)) && (parseInt(msgDate[2])===(parseInt(dd)-1)) && (parseInt(msgDate[1])===(parseInt(mm))) ) { 
        if (time!==undefined) {
            if (((msgTime[2]).substring(2,4) === 'pm')&&(parseInt(msgTime[0]) - 12 > 0 )){
                dateTime+=(parseInt(msgTime[0])+12)+":"+msgTime[1];
            }else{
                dateTime+=msgTime[0]+":"+msgTime[1];
            };
        }; 
        dateTime+=" Yesterday";
    } else {
        dateTime += date;
    }; 
    return dateTime;
};

export const capitalize = (s: string)=>{if(s==null||undefined){s='';}; return s.replace(first_char, (m: string)=>{return m.toUpperCase();});};
export const getDigits = (s: any)=> s.match(/\d+/g).map(Number);
export const getDevice = () => window.navigator.userAgent.toLowerCase();
export const isIos = () => /iphone|ipad|ipod/.test( getDevice() );

export const generateDrivers = (position: any, carList: any) => {
    const _drivers: Array<any> = [];
    if (carList) {
        for (let i = 0; i < carList.length; i++) {
            var carLoc = carList[i].position;
            _drivers.push({
                id: carList[i].id||carList[i].carID,
                coords: {
                    lat: carLoc.lat,
                    lng: carLoc.lng,
                    angle: carLoc.angle,
                }
            });
        }
    } else {
        for (let i = 0; i < 7; i++) {
            const direction = Math.random() < 0.5 ? -2 : 2;
            _drivers.push({
                id: direction,
                coords: {
                    lat: position.lat + Math.random() / direction,
                    lng: position.lng + Math.random() / direction,
                    angle: 90,
                }
            });
        }
    }
    return _drivers;
};
export const arrayEntryByObjectKey = (obj: any, list: { [x: string]: any; hasOwnProperty: (arg0: string) => any; }) => {
    var x;
    var keyOfInterest = Object.keys(obj)[0];
    for (x in list) {
        if (list.hasOwnProperty(x) && list[x][keyOfInterest] === obj[keyOfInterest]) {
            return list[x];
        }
    }
    return null;
}

export const urlB64ToUint8Array = (base64String: any)=>{
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    // const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');
    const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    };
    return outputArray;
}
export const isJSON = (myjson:any) => {
    try {
        JSON.parse(myjson);
        return true;
    } catch (e) {
        return false;
    }
}
export const checkPushPermission = async () => {
    let permissionCheck:any = null;
    try {
        if (Capacitor.getPlatform() === "web") {
            if (isIos()) {
                permissionCheck = {success: false, msg: "Web not supported by iOS", msg2: "(Not supported on iOS browsers)", msg3: "web push Notitifications is not supported on iOS devices."};
            } else {
                permissionCheck = {
                    receive: Notification.permission
                };
            }
        } else {
            permissionCheck = await PushNotifications.checkPermissions(); 
        } 
    } catch (error: any) {
        permissionCheck = {success: false, msg: "Not implemented for web", msg2: error.message};
    }
    return permissionCheck;
};
export const getPushPermission = async () => {
    var permission;
    try {
        if (Capacitor.getPlatform() === "web") {
            if (isIos()) {
                permission = {success: false, msg: "Web not supported by iOS", msg2: "(Not supported on iOS browsers)", msg3: "web push Notitifications is not supported on iOS devices."};
            } else {
                permission = {
                    receive: Notification.permission
                };
            }
        } else {
            permission = await PushNotifications.requestPermissions();
        } 
    } catch (error) {
        // console.log(error);
        permission = await getPermissions('push').then(perm=>{
            return perm
        });
    }
    
    console.log('Location permission:', permission);
    return permission;
};

export const generateHandle = (s:any)=>{
    var newString = s; /*str.startsWith() && str.endsWith();*/ 
    if ((s!=="")&&(s!==" ")&&(s!==undefined)&&(s!==null)) {
        var stringArray = s.split(" ");
        var tempNewStr = "";    
        stringArray.forEach((strSegment: any)=>{
        tempNewStr = tempNewStr+capitalize(strSegment);
        }); 
        newString = tempNewStr.replace(/\W/g, "");
        newString = newString.replace(/[^\w\s]/gi, "");
    };
    return newString;
}
export const manageDeliveredNotifications = (notificationOpt: any)=>{
    try {
        let removeOne = false;
        if (Capacitor.getPlatform() === "web") {
            
        } else {
            if (notificationOpt) {
                if (Object.keys(notificationOpt).length > 0) {

                }
            };
            if (removeOne) {
                PushNotifications.removeDeliveredNotifications(notificationOpt);
            } else {
                PushNotifications.removeAllDeliveredNotifications();
            }
        }
    } catch (error: any) {
        console.log("Error: ", error.message);
    }
}
export const addNativePushRegListeners = async (listenerCallback: any)=>{
    var pushListeners;
    try {
        // Show us the notification payload if the app is open on our device
        PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {
            console.log('notification in: ', notification)
            // setnotifications(notifications => [...notifications, { id: notification.id, title: notification.title, body: notification.body, type: 'foreground' }])
            listenerCallback({notification});
        });

        // Method called when tapping on a notification
        PushNotifications.addListener('pushNotificationActionPerformed', (notification: ActionPerformed) => {
            console.log('notification action: ', notification)
            // setnotifications(notifications => [...notifications, { id: notification.notification.data.id, title: notification.notification.data.title, body: notification.notification.data.body, type: 'action' }])
            listenerCallback({notification});
        });

        // pushRegistration = new Promise((resolve, reject)=>{
        //     // On success, we should be able to receive notifications
            PushNotifications.addListener('registration', (token: Token) => {
                // resolve(token); // call resolve with results.
                listenerCallback({token});
            });

            // Some issue with our setup and push will not work
            PushNotifications.addListener('registrationError', (error: any) => {
                console.log('Error on registration: ', error);
                // reject(error); // call resolve with results.
                listenerCallback({error});
            });
        // });
        pushListeners = {success: true, msg: "Listeners Added Successfully", msg2: "During push listner registrations.", msg3: ""};
    } catch (error: any) {
        console.log(error)
        pushListeners = {success: false, msg: "Couldn't opt in.", msg2: "During push registration.", msg3: error.message};
    };

    return pushListeners;
}
export const isPushSubscribed = async ()=>{
    const serviceWorker = await navigator.serviceWorker.ready;

    if (serviceWorker != null) {
        return serviceWorker.pushManager.getSubscription().then(function(subscription) {				
            return subscription;
        }).then(res=>{
    // console.log(res)
            return {subscribed: !(res === null), subscription: res};
        }).catch(err=>{
            console.log(err);
            return {subscribed: false};
        });
    } else {
        return {subscribed: false, swRegistration: serviceWorker};
    }
};
export const subscribeToPush = async ()=>{
    try {
        if (Capacitor.getPlatform() === "web") {
            if ('serviceWorker' in navigator || 'PushManager' in window ) { 
                const applicationServerKey = urlB64ToUint8Array(applicationServerPublicKey);

                return isPushSubscribed().then(async (subscriptionRes: any)=>{
                    console.log(subscriptionRes)
                    if (subscriptionRes.subscribed) {
                        return subscriptionRes.subscription;
                    } else {
                        const serviceWorker = await navigator.serviceWorker.ready;

                        let subscriptionOptions = {userVisibleOnly: true, applicationServerKey: applicationServerKey};
                        return serviceWorker.pushManager.subscribe(subscriptionOptions).then(function(subscription: any) { 
                            return subscription; 
                        }).catch((err: any)=>{
                            return {success: false, msg: "Couldn't opt in.", msg2: "During push registration.", msg3: err.message};
                        });
                    }
                })
            } else {
                return {success: false, msg: "Couldn't opt in.", msg2: "(During push registration.)", msg3: `Please update your browser, \nThis notification service doesn't work on this ${Capacitor.getPlatform()} platform.`};
            };
        } else {
            // Register with Apple / Google to receive push via APNS/FCM
            return PushNotifications.register();
        }
    } catch (error: any) {
        console.log(error)
        return {success: false, msg: "Couldn't opt in.", msg2: "During push registration.", msg3: error.message};
    };
}
export const unsubscribeToPush = async ()=>{
    var pushRemoval;
    try {
        // removeAllListeners

        if (Capacitor.getPlatform() === "web") {
            return isPushSubscribed().then((subscriptionRes: any)=>{
                // console.log(subscriptionRes);
                if (subscriptionRes) { 
                    if (subscriptionRes.subscription) {
                        subscriptionRes.subscription.unsubscribe();
                    }
                };
            })
        } else {
            pushRemoval = PushNotifications.removeAllListeners();
        }
    } catch (error: any) {
        pushRemoval = {success: false, msg: "Couldn't opt out", msg2: "Something went wrong.", msg3: error.message, data: error};
        
    };
    return pushRemoval;
}

// export const checkLocationPermission = async () => {
//     let permissionCheck:any = null;
//     // try {
//         permissionCheck = await Geolocation.checkPermissions();  
//     // } catch (error) {
//     //     permissionCheck = error;
//     // }
//     return permissionCheck;
// };
// export const getLocationPermission = async () => {
//     var permission;
//     try {
//         permission = await Geolocation.requestPermissions();
//     } catch (error) {
//         // console.log(error);
//         permission = await getPermissions('geolocation').then(perm=>{
//             return perm
//         });
//     }
    
//     console.log('Location permission:', permission);
//     return permission;
// };
export const getPermissions = async (name: PermissionName) => {
    try {
        if (isIos()) {
            return Promise.resolve({name: name, state: 'prompt', onchange: null});
        } else {
            return navigator.permissions.query({name: name}).then((result)=>{
                return result;
            });
        }
    } catch (error: any) {
        console.log(error.message);
        return navigator.permissions.query({name: name}).then((result)=>{
            return result;
        });
    }
}
// export const getCurrentPosition = () => {
// // export const getCurrentPosition = async () => {
//     // const coordinates = await Geolocation.getCurrentPosition();
//     // console.log(coordinates);
//     // return coordinates;
//     return Geolocation.getCurrentPosition().then(res=>{
//         return res;
//     });
// };
// export const watchCurrentPosition = async (options: any, callback: any) => {
//     var watchLoc = await Geolocation.watchPosition(options, callback);   
//     // console.log(watchLoc) 
//     var watchLocVal = parseInt(watchLoc);
//     return watchLocVal;
// };
// export const clearWatchedPosition = async (posId: any) => {
//     var clearance = await Geolocation.clearWatch(posId);    
//     return clearance;
// };

export const onDeviceStorage = async (action: string, keyValuePair: any) => {
    if (action === 'set') {
        const key= Object.keys(keyValuePair)[0];
        const value = keyValuePair[key];
        await Preferences.set({key: key, value: value});
    } else if (action === 'get') {
        var { value } = await Preferences.get({ key: keyValuePair });
        return value;
    } else if (action === 'remove') {
        await Preferences.remove({ key: keyValuePair });
    } else if (action === 'clear') {
        await Preferences.clear();
    } else if (action === 'keys') {
        const { keys } = await Preferences.keys();
        return keys;
    };
}
export const textAreaAdjust = (o: any) => {
    o.style.height = "1px";
    // var currentHeight = (o.style.height).substring(0, (o.style.height).length-2);
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        o.style.height = (o.scrollHeight+15)+"px";
    } else {
        o.style.height = (o.scrollHeight+10)+"px";
    };
}

export const makeRequests = async (state: any, options: any) => {
    var accessToken = "";
    var url = "";
    var method = "get";
    var data;
    var onUploadProgress;
    var customHeaders = {};
    if (state) {
        if (state.auth) {
            if (state.auth.user) {
                if (state.auth.user.accessToken) {
                    accessToken = state.auth.user.accessToken;
                }
            };
        }
    }
    if (options) {
        method = options.method? options.method : "get";
        url = options.url? options.url : "";
        data = options.data? options.data : "";
        customHeaders = options.headers? options.headers : {};
        onUploadProgress = options.onUploadProgress ? options.onUploadProgress: {}
    };
    var headers = {
        Authorization: "Bearer "+accessToken, ...customHeaders
    }

    
    const config = {
        headers,
        ...onUploadProgress
    };
    if (method.toLowerCase().includes("post")) {
        var formData = new FormData();
        for ( var theKey in data ) {
            formData.append(theKey, data[theKey]);
        };
        return axios.post(url, formData, config).then(res=>{
            return res.data;
        }).then(resp=>{
            return resp;
        }).catch(err=>{
            console.log(err);
            return {success: false, msg: "Request error", msg2: err.message, action: {retry: true}};
        });
    } else {
        if (data && (data !== "")) {
            var urlData = "";
            for (var key in data) {
                if (urlData !== "") {
                    urlData += "&";
                }
                urlData += key + "=" + encodeURIComponent(data[key]);
            };
            if (url.includes('?')) {
                url = `${url}&${urlData}`;
            } else {
                url = `${url}?${urlData}`;
            }
        };
        return axios.get(url, config).then(res=>{
            return res.data;
        }).then(resp=>{
            return resp;
        }).catch(err=>{
            console.log(err);
            return {success: false, msg: "Request error", msg2: err.message, action: {retry: true}};
        });
    }
}
export const arrayEquality = (a: Array<any>, b: Array<any>)=>{
    if (a.length !== b.length) return false;
    a.sort();
    b.sort();

    return a.every((element, index)=>{
        return element === b[index];
    });
}
export const getInitials = (names: string)=>{
    let initials = names.split(" ").map((n)=>n[0]).join("");

    return initials.toUpperCase();
}

export const hapticsImpactLight = async () => {
    await Haptics.impact({ style: ImpactStyle.Light });
};
export const hapticsImpactMedium = async () => {
    await Haptics.impact({ style: ImpactStyle.Medium });
};
export const hapticsImpactHeavy = async () => {
    await Haptics.impact({ style: ImpactStyle.Heavy });
};
export const hapticsVibrate = async (duration: number) => {
    await Haptics.vibrate({duration});
};

export const performBiometricVerification = async (biometricOptions: any) => {
    try {
        // if (NativeBiometric) {
        //     const result:AvailableResult = await NativeBiometric.isAvailable();
        //     console.log("result: ", result);
        //     if (result.isAvailable) {
        //         const isFaceID = result.biometryType === BiometryType.FACE_ID;
        //         console.log("isFaceID: ", isFaceID);
                
        //         return NativeBiometric.verifyIdentity({
        //             reason: biometricOptions.reason,
        //             title: biometricOptions.title,
        //             subtitle: biometricOptions.substitle,
        //             description: biometricOptions.description,
        //         })
        //         .then((verified: any)=>{
        //             console.log("Verified: ", verified);
        //             if (verified) {
        //                 return NativeBiometric.getCredentials({
        //                     server: biometricOptions.server,
        //                 // }).then((credentials: Credentials)=>{
        //                 }).then((credentials: any)=>{
        //                     console.log("Credentials: ", credentials);
        //                     return {success: true, data: credentials, status: 0};
        //                 });
        //             } else {
        //                 return {
        //                     success: false, msg: "Couldn't perform biometrics.", msg2: "", data: verified, 
        //                     status: 1
        //                 }
        //             }
        //         });
        //     } else {
        //         return {
        //             success: false, msg: "Biometrics not supported", msg2: "This device does not support bimetric Authentication.",
        //             status: -1
        //         }
        //     }
        // } else {
        //     return {
        //         success: false, msg: "Bimetrics not supported yet.", msg2: "App does not support Biometrics yet.", 
        //         status: -2
        //     };
        // }
    } catch (error: any) {
        return {
            success: false, msg: "Error while checking biometrics.", msg2: error.message, 
            status: -3
        };
    };
}

export const saveBiometricCredentials = async (biometricOptions: any)=>{ // Save user's credentials
    try {
        // NativeBiometric.setCredentials({
        //     username: biometricOptions.username,
        //     password: biometricOptions.password,
        //     server: APP_LOCAL_DOMAIN,
        // }).then((result: any)=>{
        //     return result;
        // });
    } catch (error: any) {
        return {
            success: false, msg: "Error while adding biometrics.", msg2: error.message, 
        };
    }
}

export const deleteBiometricCredentials = async ()=>{ // Delete user's credentials
    try {
        // NativeBiometric.deleteCredentials({
        //     server: APP_LOCAL_DOMAIN,
        // }).then((result: any)=>{
        //     return result;
        // });
    } catch (error: any) {
        return {
            success: false, msg: "Error while deleting biometrics.", msg2: error.message, 
        };
    }
}
export const getUserMedia = async (type: string, constraints: any) => {
    try {
        if (type === 'screen') {
            return navigator.mediaDevices.getDisplayMedia(constraints).then((stream)=>{
                return {success: true, stream};
            }).catch(err=>{
                return {success: false};
            });
        } else {
            return navigator.mediaDevices.getUserMedia(constraints).then((stream)=>{
                return {success: true, stream};
            }).catch(err=>{
                return {success: false};
            });  
        }
    } catch (error) {
        return {success: false};
    }
}
export const stopMediaTracks = (stream: any) => { 
    stream.getTracks().forEach((track: any)=>{ 
        track.stop(); 
    }); 
}

export const addStreamToCanvasTag = async (stream: any, video: any, canvas: any) => {
    // video.srcObject = stream;
    if ('srcObject' in video) {
        video.srcObject = stream;
    } else if ('mozGetUserMedia' in navigator) {
        video.mozSrcObject = stream;
    } else {
        video.src = (window.URL || window.webkitURL).createObjectURL(stream);
    }
    // return video; //This line is added when you want the video to play instead of the Canvas painting.

    const ctx = canvas.getContext('2d');
    function drawVideoToCanvas() {
        if (!video.paused) {
            if( canvas.width !== video.videoWidth || canvas.height !== video.videoHeight ) {
                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;
            };
            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        };
        requestAnimationFrame(drawVideoToCanvas);
    }
    video.addEventListener('loadedmetadata', ()=>{
        video.play();
        drawVideoToCanvas();
    });
    return canvas;
};