import { useCallback, useContext, useEffect, useState } from "react";
import { IonButton, IonIcon} from "@ionic/react"
import { addCircle, qrCode, text } from "ionicons/icons";

import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { set, ref } from 'firebase/database';

import { AppContext } from "../../contexts/AppContextProvider";
import { cloudFireStoreDB, realtimeDb } from '../../Services/firebase-config';
import { hapticsImpactHeavy, isJSON } from "../../Services/utils";


import ScanQrCode from "../ScanQrCode";

import './index.css';
const Configuration:React.FC<any> = ({routerRef, onSegment, setShowLoading, setShowAlertState, isDesktop})=>{
    const { state } = useContext(AppContext);
    const [isFirstLoad, setIsFirstLoad] = useState(false);
    const [isDeviceLinkageOpen, setIsDeviceLinkageOpen] = useState(false);

    const wifiProvisioning = ()=>{
        setIsDeviceLinkageOpen(true);
    }
    // console.log("issDesktop: ",isDesktop)
    const addDevicePairing = useCallback(async(preparedQrDdata: any)=>{
        // Adding device under user's database profile.
        if (state.auth.user.parentAccount) {
            setShowLoading((s:any)=>({...s, showLoadingMessage: "Associating the device...", showLoading: true, triggered: true}));
            await addDoc(collection(cloudFireStoreDB, "devices"), {
                ownerID: parseInt(state.auth.user.parentAccount),
                adminToken: state.auth.user.accessToken,
                identifier: preparedQrDdata,
                customName: preparedQrDdata.name || "",
                timeAdded: serverTimestamp(),
                thresholds: {
                    'min-pH': 3,
                    'max-pH': 10,
                    'min-temperature': 0,
                    'max-temperature': 50,
                }
            }).then((addingRes)=>{
                // var currentdateTime:any = new Date(dateTimeNow()).getSeconds();
                var currentdateTime:any = new Date().getTime();
                var initialEntry = {
                    readings: {[currentdateTime]: {temperature: 25, pH: 7, timestamp: currentdateTime}},
                    thresholds: {
                        'min-pH': 3,
                        'max-pH': 10,
                        'min-temperature': 0,
                        'max-temperature': 50,
                    }
                };
                // var initialEntry = [{Temperature: 25, PH: 7, timestamp: currentdateTime}];
                set(ref(realtimeDb, `devices/${preparedQrDdata.identifier}`), initialEntry);

                setTimeout(() => {
                    hapticsImpactHeavy();
                    setTimeout(() => {
                        setShowLoading((s:any)=>({...s, showLoading: false}));
                    }, 300);
                    var alertStateVarsSuccess = {
                        header: "Successful.", subHeader: "Device pair", message: "Continue see list of devices", inputs: [], 
                        buttons: [
                            { text: 'Continue', handler: () => { 
                                // history.goBack();
                                onSegment('insights');
                            }
                        }
                    ]};
                    setTimeout(() => {
                        setShowAlertState((showAlert:any)=>({...showAlert, ...alertStateVarsSuccess, showAlert: true}));
                    }, 600);
                }, 300);
            });
        } else {
            var alertStateVarsSuccess = {
                header: "Error.", subHeader: "No main account", message: "Continue see list of devices", inputs: [], 
                buttons: [
                    { text: 'Back', handler: () => { 
                        // onSegment('insights');
                    }
                }
            ]};
            setTimeout(() => {
                setShowAlertState((showAlert:any)=>({...showAlert, ...alertStateVarsSuccess, showAlert: true}));
            }, 300);
        }
    },[onSegment, setShowAlertState, state.auth.user.accessToken, state.auth.user.parentAccount, setShowLoading])

    const manualDeviceLink  = useCallback(()=>{
        var alertStateVarsSuccess = {
            header: "Manual setup.", subHeader: "Pairing process", message: "Enter these mandatory fields", 
            inputs: [
                {name: 'name', label: 'Device name', placeholder: 'Distict device name', type: 'text', required: true},
                {name: 'identifier', label: 'Device ID', placeholder: 'Device ID', type: 'text', require: true},
            ], 
            buttons: [
                { text: 'Submit', handler: (inputData:any) => { 
                    if ((inputData.name === "")||(inputData.identifier === "")) {
                        setTimeout(() => {
                            manualDeviceLink();
                        }, 300);
                    } else {
                        inputData.identifier = (inputData.identifier).replaceAll(".", "-");
                        addDevicePairing(inputData);
                    }
                }
            }
        ]};
        setShowAlertState((showAlert:any)=>({...showAlert, ...alertStateVarsSuccess, showAlert: true}));
    },[addDevicePairing,setShowAlertState])

    const handlScanResults = async (result: any) => {
        let alertStateVarsSuccess:any;
        try {
            if (result) {
                console.log("handlScanResults: ", result, result.data);
                if (result.data) {
                    if (isJSON(result.data)) {
                        var preparedQrDdata = JSON.parse(result.data);
                        if (preparedQrDdata) {
                            // dispatch(setQrConfirmation({confirmation: {data: preparedQrDdata}, linkStatus: false, presentingElement: routerRef}));
                            preparedQrDdata.identifier = (preparedQrDdata.identifier).replaceAll(".", "-");
                            addDevicePairing(preparedQrDdata);
                        }
                    } else {
                        var completeSetupPrompt = {
                            header: "Name of device.", 
                            subHeader: `ID: ${result.data}`, 
                            message: "(Give it a name)", 
                            inputs: [
                                {name: 'name', label: 'Device name', placeholder: 'Distict device name', type: 'text', required: true},
                            ], 
                            buttons: [
                                { text: 'Submit', handler: (inputData:any) => { 
                                    if ((inputData.name === "")||(inputData.identifier === "")) {
                                        setTimeout(() => {
                                            manualDeviceLink();
                                        }, 300);
                                    } else {
                                        inputData.identifier = (result.data).replaceAll(".", "-");
                                        addDevicePairing(inputData);
                                    }
                                }
                            }
                        ]};
                        setTimeout(() => {
                            setShowAlertState((showAlert:any)=>({...showAlert, ...completeSetupPrompt, showAlert: true}));
                        }, 600);
                    };
                } else {
                    alertStateVarsSuccess = {
                        header: "Error.", subHeader: "Something went wrong", message: "Unsupported", inputs: [], 
                        buttons: [
                            { text: 'Back', handler: () => { 
                                // onSegment('insights'); 
                            }
                        }
                    ]};
                    setTimeout(() => {
                        setShowAlertState((showAlert:any)=>({...showAlert, ...alertStateVarsSuccess, showAlert: true}));
                    }, 300);
                }
            };
        } catch (error:any) {
            console.log(error.message);
            alertStateVarsSuccess = {
                header: "Error.", subHeader: "Something went wrong", message: error.message, inputs: [], 
                buttons: [
                    { text: 'Back', handler: () => { 
                        // onSegment('insights');
                    }
                }
            ]};
            setTimeout(() => {
                setShowAlertState((showAlert:any)=>({...showAlert, ...alertStateVarsSuccess, showAlert: true}));
            }, 300);
        }
    };
    useEffect(()=>{
        if(isDesktop ==="Yes" && !isFirstLoad){
            manualDeviceLink();
            setIsFirstLoad(true);
        }
    },[isDesktop, isFirstLoad, manualDeviceLink])


    return (
        <>
        <div className="deviceConfigView">
            <br/>
            <br/>
            <br/>
            {(isDesktop ==="Yes")?(
                <>
                <br/>
                <br/>
                <div className="addWiFiButtonHolder">
                    <IonButton size="small" className="addWiFiButton" onClick={manualDeviceLink}>Add Device <IonIcon className="btnIcon" icon={addCircle}/> </IonButton>
                </div>
                </>
            ):(
                <>
                <div className="addWiFiButtonHolder">
                    <IonButton size="small" className="addWiFiButton" onClick={wifiProvisioning}>Link a device <IonIcon className="btnIcon" icon={qrCode}/> </IonButton>
                </div>
                <br/>
                <br/>
                <div className="theOptionSeparator">
                    <span className="orLine"></span>
                    <span className="orLine"></span>
                    <span className="orText">OR</span>
                </div>
                <br/>
                <br/>
                <div className="addWiFiButtonHolder">
                    <IonButton size="small" className="addWiFiButton" onClick={manualDeviceLink}>Manual setup <IonIcon className="btnIcon" icon={text}/> </IonButton>
                </div>
                </>
            )}
            
        </div>
        <ScanQrCode myPresentingElemRef={routerRef} isOpen={isDeviceLinkageOpen} setIsDeviceLinkageOpen={setIsDeviceLinkageOpen} handlScanResults={handlScanResults}/>
        </>
    )
}
export default Configuration;