import React from 'react';
import { IonCard, IonIcon, IonPage, IonTitle, useIonViewWillEnter } from '@ionic/react';
import { arrowForward } from 'ionicons/icons';
import { useCallback } from 'react';
import { useHistory } from 'react-router';
import './index.css';
import { localDomain } from '../../Services/utils';
const LandingPage: React.FC = ()=>{

    useIonViewWillEnter(() => {
        const bottomNav = document.getElementById("navigationTabBarRef");
        if (bottomNav) {
            bottomNav.style.display = "none";
        };
    });

    const history = useHistory()
    const moveToPage = useCallback(() => {
        const to = `/home`
        history.push(to)
        // window.open("/help.html", "_self");
    },[ history]);

    return (
        <IonPage>
            <div className="landingMainHolder">
                <div className="companyOverView">
                    <div className="otherPageLink">
                        {/* <a href={localDomain("about.html")}>About us</a> */}
                        <a href={"https://appimate.com/about.html"} target='_blank' rel="noreferrer">About us</a>
                        <a href={localDomain("help.html")}>Help</a>
                        <a href={localDomain("terms.html")}>Terms</a>
                    </div>
                    <div className="companyContextGraphics">
                        <div className="companyContextTextGroups">
                            <h1>Stay,</h1>
                            <h1>Connected.</h1>
                            {/* <h1>experiences.</h1> */}
                        </div>
                        <div className="highlightLearningPlatform">
                            <IonCard className="theAppimateAppIcon" routerLink='/home'>
                                <img decoding="async" loading="lazy" src="/assets/icons/icon-512.webp" alt="App Icon"/>
                            </IonCard>
                            <div className="theAppimateAppDirect" onClick={moveToPage} >
                                <p>Swift Link</p>
                                <IonIcon class='myIcon' icon={arrowForward} />
                            </div>
                        </div>
                    </div>
                    <div className="appimateProducts" >
                        <IonTitle>Powered by: Appimate</IonTitle>
                        <div className="appsList">
                            <IonCard className="theApps"></IonCard>
                            <IonCard className="theApps"></IonCard>
                            <IonCard className="theApps"></IonCard>
                        </div>
                    </div>
                </div>
                {/* <div className="loginFormHolder" > */}
                    <IonCard className="loginFormHolder" routerLink='/home'>
                        
                    </IonCard>
                {/* </div> */}
            </div>
        </IonPage>
    );
}
export default LandingPage;