import React from 'react';
import { IonButton, IonIcon, IonInput, IonItem, IonLabel } from '@ionic/react';
import { lockClosed,} from 'ionicons/icons';

import './index.css';

const  ResetPassword:React.FC<any> = ({ranKey, appimateID, fowardOnsubmit, formEntries}) => {
    return (
        <div className="topDiv">
            <br/>
            <form method="POST" action="api/authentication?logIn=4&mod=0" onSubmit={fowardOnsubmit}>
                <input type="hidden" name="in_App" defaultValue="iot" />
                <input type="hidden" name="type" defaultValue="resetPassword" />
                <input type="hidden" name="in_AppimateID" defaultValue={appimateID} />
                <input type="hidden" name="ranKey" defaultValue={ranKey} />
                <IonItem mode='ios' className="ionMyItem">
                    <IonLabel mode='ios' position="floating" className="theLabel" >Password</IonLabel>
                    <IonIcon mode='ios' className="iconPart" icon={lockClosed} />
                    <IonInput mode='ios' name="in_Password" type="text" className="theInput" value={(formEntries&&formEntries['in_Password'])?(formEntries['in_Password']):("")} required />
                </IonItem>
                <IonItem mode='ios'className="ionMyItem">
                    <IonLabel mode='ios' position="floating" className="theLabel" >Repeat password</IonLabel>
                    <IonIcon mode='ios' className="iconPart" icon={lockClosed} />
                    <IonInput mode='ios' name="in_PasswordRepeat" type="text" className="theInput" value={(formEntries&&formEntries['in_PasswordRepeat'])?(formEntries['in_PasswordRepeat']):("")} required />
                </IonItem>
                <br/>
                <br/>
                <IonButton mode='ios' expand="block" type="submit" className="ion-activatable ripple-parent subButton">Submit</IonButton>
            </form>
            <br/>
            <br/>
            <br/>
            <div className="myButtons">   
                {/* <p className="myForgot" >Didn't recieve otp?  <span className='mySpan'> Resend</span></p>            */}
                {/* <p className="myForgot" onClick={()=>routerFunction('login')}>Back to sign in? <span className='mySpan'> Sign in.</span></p>            */}
            </div>
        </div>
    );
}

export default ResetPassword;