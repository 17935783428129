import { 
    arrayEntryByObjectKey
} from "./utils";


var isOnline = true;
var colorMode = "light";

if (navigator.onLine === false) {
    isOnline = false; 
}; 
if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    colorMode = "dark";
};

export const initialState = {
    initialAuth: false,
    auth: {
        user: null
    },
    user: {
        type: null,
        pushAccess: false,
        locationAccess: false,
        radius: 2000,
        country: 'za',
        position: {
            coords: {
                lat: -33.021446,
                lng: 27.905659,
                angle: 0.00,
            },
            name: "My location"
        },
    },
    currentDevices: {
        devices: [],
        readings: [],
    },
    devicesStream: {
        unsubscribe: null
    },
    microTransactions: {
        paymentMethod: "card",
        cardDetails: {},
        paymentStatus: false,
        payment: {
            show: false,
            presentingElement: null
        },
        selectedProduct: {},
        itemsToPay: [
        ],
    },
    paymentConfirmation: {
        confirmation: {},
        presentingElement: null
    },
    qrCodeInfo: {
        confirmation: {},
        presentingElement: null
    },
    ui: {
        trackerOpen: false,
        colorMode: colorMode,
    },
    internetStatus: true,
    map: {
        // mapsKey: "AIzaSyBmv-_M9GHhUwwunj8U-1iWThATyApix4Y", //Luthando's Google API key
        mapsKey: "AIzaSyASReJfi0Rmp8bE3pJrdPEveu9w5M9YdCM", //Appimate's Transi Google API key
        libraries: ["places"],
        mapIdLight: "9a92519800faf923", //under Appimate's account.
        mapIdDark: "b181cac70f27f5e6", //found from the internet.
        stokeColorLight: "#000000", //found from the internet.
        stokeColorDark: "#1976D2", //found from the internet.
        circles: [
            // {type: closeOptions, radius: 1500},
            // {type: middleOptions, radius: 3000},
            // {type: farOptions, radius: 4500},
        ]
    },
    isOnline,
    appState: true,
};


export const reducer = (state, action) => {
    

    switch (action.type) {
        case 'START_TRACKING': {
            return {
                ...state
            }
        }
        case 'SET_PUSH_ACCESS': {
            return {
                ...state,
                user: {
                    ...state.user,
                    pushAccess: action.pushAccess,
                }
            }
        }
        case 'SET_LOCATION_ACCESS': {
            return {
                ...state,
                user: {
                    ...state.user,
                    locationAccess: action.locationAccess,
                }
            }
        }
        case 'SET_LOCATION': {
            return {
                ...state,
                user: {
                    ...state.user,
                    position: action.location,
                }
            }
        }
        case 'SET_DRAG_LOCATION': {
            return {
                ...state,
                user: {
                    ...state.user,
                    dragPosition: action.location,
                }
            }
        }
        case 'SET_TRIP_REQUEST': {
            return {
                ...state,
                tripRequestInfo: {
                    ...state.tripRequestInfo,
                    ...action.newTripInfo
                }
            }
        }
        case 'SET_MICRO_TRANSACTION': {
            if ('show' in action.newTransactionInfo) {
                var showPart = action.newTransactionInfo.show;
                if ('presentingElement' in action.newTransactionInfo) {
                    var presentingElement = action.newTransactionInfo.presentingElement;
                    return {
                        ...state,
                        microTransactions: {
                            ...state.microTransactions,
                            payment: {
                                ...state.microTransactions.payment,
                                show: showPart,
                                presentingElement
                            }
                        }
                    }
                } else {
                    return {
                        ...state,
                        microTransactions: {
                            ...state.microTransactions,
                            payment: {
                                ...state.microTransactions.payment,
                                show: showPart,
                            }
                        }
                    }
                }
            } else {
                var newTransactionInfo = action.newTransactionInfo;
                return {
                    ...state,
                    microTransactions: {
                        ...state.microTransactions,
                        ...newTransactionInfo,
                    },
                }
            }
        }
        case 'SET_ITEMS_TO_PAY': {
            return {
                ...state,
                microTransactions: {
                    ...state.microTransactions,
                    itemsToPay: action.itemsToPay
                }
            }
        }

        case 'SET_TRIP_TRACKER': {
            return {
                ...state,
                trackingTrip: {
                    ...state.trackingTrip,
                    ...action.trackingData
                }
            }
        }
        case 'SET_USER_TYPE': {
            return {
                ...state,
                user: {
                    ...state.user,
                    type: action.userType.role
                },
                car: action.userType.car
            }
        }
        case 'SET_RATE_TRIP': {
            var rateTrip = action.trip;
            if (rateTrip) rateTrip = rateTrip.data;
            return {
                ...state,
                tripRequestInfo: {
                    ...state.tripRequestInfo,
                    rateTrip
                }
            };
        }
        case 'SET_RETRY_TRIP': {
            return {
                ...state,
                tripRequestInfo: {
                    ...state.tripRequestInfo,
                    retryTrip: action.trip
                }
            };
        }
        case 'SET_CARS_AROUND': {
            let oldCarsAround = state.carsAround;
            let setNewCarsAround = [];
            if(action.carsAround.length > 0){
                let newCarsAroundInfo = action.carsAround;
                if (action.carsAround[0].status < 0) {
                    if (oldCarsAround.length > 0 ) {
                        let carsToKeep = oldCarsAround.filter(c => (c.id||c.carID) !== (newCarsAroundInfo[0].id || newCarsAroundInfo[0].carID));  
                        setNewCarsAround = [...carsToKeep];
                    }
                    setNewCarsAround = [...setNewCarsAround];
                } else {
                    if (oldCarsAround.length > 0 ) {
                        let carsToKeep = oldCarsAround.filter(c => (c.id||c.carID) !== (newCarsAroundInfo[0].id || newCarsAroundInfo[0].carID));  
                        setNewCarsAround = [...carsToKeep];
                    };
                    setNewCarsAround = [...setNewCarsAround, ...action.carsAround];
                }
            };

            return {
                ...state,
                carsAround: [
                    ...setNewCarsAround,
                ]
            }
        }
        case 'SET_LIST_OF_TRIPS': {
            let oldTrips = state.listOfTrips;
            var setNewTrips = [];
            if(action.trips.length > 0){
                if (action.trips[0].state === -0.5) {
                    if ((oldTrips.length > 0 && !arrayEntryByObjectKey({type: "loader"}, oldTrips))) {
                        let newTripInfo = action.trips;
                        let tripToKeep = oldTrips.filter(t => t.id !== newTripInfo[0].id);
                        setNewTrips = [...tripToKeep];
                    };
                    setNewTrips = [...setNewTrips];
                } else {
                    if ((oldTrips.length > 0 && !arrayEntryByObjectKey({type: "loader"}, oldTrips))) {
                        let newTripInfo = action.trips;
                        let tripToKeep = oldTrips.filter(t => t.id !== newTripInfo[0].id);
                        setNewTrips = [...tripToKeep];
                    };
                    setNewTrips = [...setNewTrips, ...action.trips];
                }
            }
            return {
                ...state,
                listOfTrips: [
                    ...setNewTrips,
                ]
            }
        }
        case 'UPDATE_LIST_OF_TRIPS': {
            var updatedTrips = [];
            let recievedTripUpdate = action.trip;
            let oldTrips = state.listOfTrips;
            let tripToUpdate = oldTrips.find(t => t.id === recievedTripUpdate.id);
            if (tripToUpdate) {
                updatedTrips = oldTrips.map((oldTrip, tripKey)=>{
                    if (tripToUpdate.id === oldTrip.id) {// Do the trip Update;
                        let driver = recievedTripUpdate.updateObj.driver;
                        let carID = recievedTripUpdate.updateObj.carID;
                        let tripState = recievedTripUpdate.updateObj.state;
                        let stateChanges = recievedTripUpdate.updateObj.stateChanges;
                        let updatedObject = {...oldTrip, driver, carID, state: tripState, stateChanges};
                        if (recievedTripUpdate.updateObj.vehicle) {
                            let vehicle = recievedTripUpdate.updateObj.vehicle;

                            updatedObject = {
                                ...updatedObject, 
                                vehicle: {
                                    ...oldTrip.vehicle,
                                    ...vehicle
                                }
                            };
                        }
                        if (recievedTripUpdate.updateObj.driverProfile) {
                            let driverProfile = recievedTripUpdate.updateObj.driverProfile;
                            updatedObject = {...updatedObject, driverProfile};
                        }
                        return updatedObject;
                    };
                    return oldTrip;
                });
            } else {
                console.log("Updating a locally unrecognised trip.")
            };
            return {
                ...state,
                listOfTrips: [
                    ...updatedTrips
                ]
            }
        }
        case 'PAYMENT_CONFIRMATION': {
            if ('paymentStatus' in action.confirmation) {
                var paymentStatus = action.confirmation.paymentStatus;
                if ('confirmation' in action.confirmation) {
                    let paymentConfirmation = action.confirmation.confirmation;
                    return {
                        ...state,
                        paymentConfirmation: {
                            ...state.paymentConfirmation,
                            confirmation: {
                                ...state.paymentConfirmation.confirmation,
                                ...paymentConfirmation
                            },
                            paymentStatus
                        },
                        microTransactions: {
                            ...state.microTransactions,
                            paymentStatus
                        }
                    };
                } else {
                    return {
                        ...state,
                        microTransactions: {
                            ...state.microTransactions,
                            paymentStatus
                        }
                    };
                }
            } else {
                let paymentConfirmation = action.confirmation;
                return {
                    ...state,
                    paymentConfirmation: {
                        ...state.paymentConfirmation,
                        ...paymentConfirmation
                    }
                };  
            }
        }
        case 'SET_QRCODE_INFO': {
            let qrCodeInfo=  action.confirmation;
            return {
                ...state,
                qrCodeInfo: {
                    ...state.qrCodeInfo,
                    ...qrCodeInfo
                }
            }
        }
        case 'SET_INITIAL_AUTH': {
            return {
                ...state,
                initialAuth: action.value,
            }
        }
        case 'SET_CURRENT_DEVICES': {
            if (action.deviceInformation) {
                if ( 'devices' in action.deviceInformation ) {
                    var devices = action.deviceInformation.devices;
                    return {
                        ...state,
                        currentDevices: {
                            ...state.currentDevices,
                            devices
                        }
                    }
                } else if ( 'readings' in action.deviceInformation ) {
                    var readings = action.deviceInformation.readings;
                    return {
                        ...state,
                        currentDevices: {
                            ...state.currentDevices,
                            readings
                        }
                    }
                } else {
                    return {
                        ...state,
                    }
                }
            } else {
                return {
                    ...state,
                }
            }
        }
        case 'SET_DEVICES_STREAM': {
            return {
                ...state,
                devicesStream: {
                    ...state.devicesStream,
                    unsubscribe: action.unsubscribe,
                }
            }
        }
        case 'UI': {
            return {
                ...state,
                ui: {
                    ...state.ui,
                    colorMode: action.colorMode
                }
            }
        }
        case 'SET_INTERNET_CONNECTIVITY': {
            return {
                ...state,
                internetStatus: action.value
            }
        }
        case 'SOCKET_CONNECTION': {
            return {
                ...state,
                socketConnection: action.value
            }
        }
        case 'SET_APP_STATE': {
            return {
                ...state,
                appState: action.appState
            }
        }
        case 'SET_MULTIPLE_STATE_VALUES': {
            return {
                ...state,
                ...action.newStateValues
            }
        }
        case 'SET_IS_ONLINE': {
            return {
                ...state,
                isOnline: action.value
            }
        }
        case 'SET_PROFILE_PIC': {
            return {
                ...state,
                auth: {
                    ...state.auth,
                    user: {
                        ...state.auth.user,
                        profilePic: action.profilePic
                    }
                }
            }
        }
        case 'LOGGED_IN': {
            return {
                ...state,
                auth: {
                    ...state.auth,
                    user: action.user
                },
                user: {
                    ...state.user,
                    locationAccess: false,
                },
                initialAuth: action.user.initialAuth
            }
        }
        case 'LOGOUT': {
            var newState = initialState;
            return newState;
        }

        default: {
            return state;
        }
    }
};
  
export const loggedIn = (user) => ({
    type: 'LOGGED_IN',
    user
});
export const logout = () => ({
    type: 'LOGOUT'
});
export const setIsOnline = (value)=>({
    type: 'SET_IS_ONLINE',
    value
});
export const setInitialAuth = (value) => ({
    type: 'SET_INITIAL_AUTH',
    value
})
export const setIsSubscribed = (isSubscribed)=>({
    type: 'SET_IS_SUBSCRIBED',
    isSubscribed
})
export const setSubscriptionOptions = (subscriptionOptions)=>({
    type: 'SET_SUBSCRIPTION_OPTIONS',
    subscriptionOptions
})
export const setMicroTransactionPayment = (newTransactionInfo) => ({
    type: 'SET_MICRO_TRANSACTION_PAYMENT',
    newTransactionInfo
})
export const setInternetConnectivity = (value)=>({
    type: 'SET_INTERNET_CONNECTIVITY',
    value
});
export const setConnectionState = (value)=>({
    type: 'SOCKET_CONNECTION',
    value
});
export const setAppState = (appState)=>({
    type: 'SET_APP_STATE',
    appState
});
export const setCurrentChat = (currentChat)=>({
    type: "SET_CURRENT_CHAT",
    currentChat
})
export const setMessageCounter = (newCount)=>({
    type: 'SET_MSG_COUNT',
    newCount
});
export const setNotifyCounter = (newCount)=>({
    type: 'SET_NOTIFY_COUNT',
    newCount
});
export const setDriverState = (online) => ({
    type: 'SET_DRIVER_STATE',
    online
});
export const setPushAccess = (pushAccess) => ({
    type: 'SET_PUSH_ACCESS',
    pushAccess
});
export const setLocationAccess = (locationAccess) => ({
    type: 'SET_LOCATION_ACCESS',
    locationAccess
});
export const setLocation = (location) => ({
    type: 'SET_LOCATION',
    location
});
export const setDragLocation = (location) => ({
    type: 'SET_DRAG_LOCATION',
    location
});
export const setTripDirections = (newTripInfo) => ({
    type: 'SET_TRIP_REQUEST',
    newTripInfo
});
export const setMicroTransactions = (newTransactionInfo) => ({
    type: 'SET_MICRO_TRANSACTION',
    newTransactionInfo
});
export const setItemsToPay = (itemsToPay)=>({
    type: 'SET_ITEMS_TO_PAY',
    itemsToPay
})
export const setTripTracker = (trackingData) => ({
    type: 'SET_TRIP_TRACKER',
    trackingData
});
export const setCarsAround = (carsAround) => ({
    type: 'SET_CARS_AROUND',
    carsAround
})
export const setListOfTrips = (trips) => ({
    type: 'SET_LIST_OF_TRIPS',
    trips
});
export const updateListOfTrips = (trip) => ({
    type: 'UPDATE_LIST_OF_TRIPS',
    trip
});
export const setRetryTrip = (trip) => ({
    type: "SET_RETRY_TRIP",
    trip
})
export const setRateTrip = (trip) => ({
    type: "SET_RATE_TRIP",
    trip
});

export const setColorMode = (color) => ({
    type: 'UI',
    colorMode: color
});
export const setUserType = (userType) => ({
    type: 'SET_USER_TYPE',
    userType
});
export const setPaymentConfirmation = (confirmation) => ({
    type: 'PAYMENT_CONFIRMATION',
    confirmation
})
export const setQrConfirmation = (confirmation) => ({
    type: 'SET_QRCODE_INFO',
    confirmation
})

export const setMainFeed = (feed) => ({
    type: 'SET_MAIN_FEED',
    feed
})
export const setProfilePic = (profilePic)=>({
    type: 'SET_PROFILE_PIC',
    profilePic
})
export const setCurrentDevices = (deviceInformation) => ({
    type: 'SET_CURRENT_DEVICES',
    deviceInformation
})
export const setDevicesStream = (devicesStream)=>({
    type: 'SET_DEVICES_STREAM',
    devicesStream
})
export const setMultipleStateValues = (newStateValues)=>({
    type: 'SET_MULTIPLE_STATE_VALUES',
    newStateValues
})

export const getCurrentChat = (state) => state.user.currentChat;
export const getUserID = (state) => {
    if (!state.auth.user) return null;
    return state.auth.user.uid;
};
export const getUserRole = (state) => {
    if (!state.user) return null;
    return state.user.type;
};
export const getUserNames = (state) => {
    if (!state.auth.user) return null;
    return state.auth.user.firstname+" "+state.auth.user.lastname;
};
export const getUserAuth = (state) => state.auth.user;
export const getUser = (state) => state.user;
export const getUserPosition = (state) => state.user.position;
export const getStartingPoint = (state) => state.tripRequestInfo.locations.origin;
export const getDraggedLocation = (state)=> state.user.dragPosition;

export const getCurrentDevices = (state)=> state.currentDevices.devices;
export const getCurrentReadings = (state)=> state.currentDevices.readings;
export const getOnlineCars = (state) => state.cars;
export const getDevicesStream = (state) => state.devicesStream;
export const getConnectivityState = (state) => state.internetStatus;
export const getMySubscription = (state) => {
    let mySubscription = state.user.isSubscribed.selectedProduct;
    if (mySubscription) {
        mySubscription = mySubscription[0];
        mySubscription = {
            [mySubscription.subsKey]: {...mySubscription}
        }
        return mySubscription;
    } else {
        return {};
    }
};

export const checkIsOnline = (state) => state.isOnline;
export const isUsingMyLocation = (state)=> state.user.position.coords === initialState.user.position.coords;