import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { IonButton, IonCard, IonIcon, IonSkeletonText} from "@ionic/react"
import { radioButtonOff } from "ionicons/icons";

import { 
    collection, 
    // limit, 
    onSnapshot, orderBy, query, where 
} from 'firebase/firestore';
import { query as rtdbQuery, onValue, ref, limitToFirst } from 'firebase/database';

import { AppContext } from "../../contexts/AppContextProvider";
import { getDevicesStream, getUserID, setCurrentDevices, setDevicesStream } from "../../Services/State";
import { arrayEntryByObjectKey, sleep, supportedInsights } from "../../Services/utils";
import { cloudFireStoreDB, realtimeDb } from '../../Services/firebase-config';


import './index.css';
const Insights:React.FC<any> = ({routerRef, onSegment, setShowLoading, setShowAlertState})=>{
    const { state, dispatch } = useContext(AppContext);

    var insightTypeRef = useRef([{type: 'loader', value: 7, message: "Avg. pH value", items: 0}, {type: 'loader', value: 0, message: "Avg. Temp. value", items: 0}, {type: 'loader', value: 0, message: "Avg. Temp. value", items: 0}, {type: 'loader', value: 0, message: "Avg. Temp. value", items: 0}]);
    const [myDevices, setMyDevices] = useState<any>([]);
    const [streamDevices, setStreamDevices] = useState<any>([]);
    const [justLoaded, setJustLoaded] = useState(true);
    const [devicesLoaded, setDevicesLoaded] = useState(true);
    const [deviceSynced, setDeviceSynced] = useState(false);
    const [haveDevices, setHaveDevices] = useState(true);
    
    const history  = useHistory();

    const gotoConfigure = ()=>{
        onSegment('configure');
    }
    const fetchReadings = useCallback(async (deviceIdentifier:any)=>{
        if (!(arrayEntryByObjectKey({deviceIdentifier: deviceIdentifier.identifier.identifier}, streamDevices))) {
            setDeviceSynced(true);
            var deviceDataFolder = (deviceIdentifier.identifier.identifier).replaceAll(".", "-");
            const topUserPostsRef = rtdbQuery(ref(realtimeDb, `devices/${deviceDataFolder}/readings`), limitToFirst(20));
            onValue(topUserPostsRef, (snapshot)=>{
                const data = snapshot.val();
                var newDeviceData:any = [];
                if (data) {
                    if (Array.isArray(data)) {
                        data.forEach((rawDeviceData: any)=>{
                            var deviceData = {
                                value: rawDeviceData,
                                deviceIdentifier: deviceIdentifier.indentifier,
                            };
                            newDeviceData.push(deviceData);
                        });
                    } else {
                        var deviceData:any = {dataHistory:{}};
                        for (const key in data) {
                            if (Object.prototype.hasOwnProperty.call(data, key)) {
                                const rawDeviceData = data[key];
                                deviceData = {
                                    ...deviceData,
                                    value: rawDeviceData,
                                    deviceIdentifier,
                                    dataHistory: {
                                        ...deviceData.dataHistory,
                                        [key]: rawDeviceData
                                    },
                                };
                            }
                        };
                        if (Object.keys(deviceData).length > 1) {
                            newDeviceData.push(deviceData);
                        }
                    }
                };
                setStreamDevices((sd: any)=>{
                    var usedOldSD:any = [];
                    if (sd.length > 0) {
                        var updatedDeviceReadings:any = [];
                        newDeviceData.forEach((newDevice: any, key: number) => {
                            if (arrayEntryByObjectKey({deviceIdentifier: newDevice.deviceIdentifier}, sd)) {
                                updatedDeviceReadings.push({
                                    ...sd[key],
                                    ...newDevice
                                });
                                usedOldSD.push(newDevice);
                            } else {
                                updatedDeviceReadings.push(newDevice);
                            }
                        });
                        sd.forEach((oldDevice: any, oldKey: number)=>{
                            if ( ! arrayEntryByObjectKey({deviceIdentifier: oldDevice.deviceIdentifier}, usedOldSD) ) {
                                updatedDeviceReadings.push(oldDevice);
                            }
                        });
                    } else {
                        updatedDeviceReadings =  newDeviceData;
                    };
                    dispatch(setCurrentDevices({readings: updatedDeviceReadings}))
                    return (updatedDeviceReadings);
                });
            });
        };
    }, [streamDevices, dispatch]);
    const fetchDevices = useCallback(()=>{
        if (state.auth.user) {
            var ownerID = parseInt(state.auth.user.parentAccount)|| getUserID(state);
            var collectionRef = collection(cloudFireStoreDB, "devices");
            var q = query(
                collectionRef, 
                where("ownerID", "==", ownerID),
                orderBy("timeAdded", "asc"), 
                // limit(3)
            );
            const unsubscribe = onSnapshot(q, (snapshot) => {
                var newDevices:any = [];
                setDevicesLoaded((sdl:any)=>{
                    setHaveDevices(((snapshot.docChanges().length > 0)&&(sdl))?(true):(false));
                    return sdl;
                });
                snapshot.docChanges().forEach((change) => {
                    var docID =  change.doc.id;
                    var docData =  change.doc.data();
                    // console.log(docData);
                    docData.id = docID;
                    docData.timeAdded = docData.timeAdded.toDate().toDateString();
                    var alertStateVarsSuccess = {};
                    if (change.type !== "removed") {
                        if (change.type === "added") {
                            newDevices.push(docData);
                        };
                        if (change.type === "modified") {
                            // console.log(change)
                            // alertStateVarsSuccess = {
                            //     header: "Device updated.", subHeader: "update", message: "Device-Account association succeeded", inputs: [], 
                            //     buttons: [
                            //         { 
                            //             text: 'Okay', handler: () => { 
                            //         }
                            //     }
                            // ]};
                            // setTimeout(() => {
                            //     setShowAlertState((showAlert:any)=>({...showAlert, ...alertStateVarsSuccess, showAlert: true}));
                            // }, 300);
                        };
                    } else {
                        if (change.type === "removed") {
                            alertStateVarsSuccess = {
                                header: "Device removed.", subHeader: "deletion happened", message: "the device has been unlinked from your account", inputs: [], 
                                buttons: [
                                    { text: 'Okay', handler: () => { 
                                    }
                                }
                            ]};
                            setTimeout(() => {
                                setShowAlertState((showAlert:any)=>({...showAlert, ...alertStateVarsSuccess, showAlert: true}));
                            }, 300);
                        };
                    };
                });
                if (newDevices.length < 1) {
                    setStreamDevices([]);
                };
                
                setMyDevices((md:any)=>{
                    if ( !deviceSynced && (newDevices.length !== md.length) ) {
                        setDevicesLoaded(false);
                        var unsubscription = getDevicesStream(state).unsubscribe;
                        if (typeof unsubscription === "function") {
                            getDevicesStream(state).unsubscribe(); // 
                        };
                        
                        setDeviceSynced(false);
                        dispatch(setDevicesStream(unsubscribe));
                        dispatch(setCurrentDevices({devices: [...md, ...newDevices]}));
                        return ([...md, ...newDevices]);
                    } else {
                        return md;
                    };
                })
            }, (err)=>{
                console.log(err);
            });
        };
    }, [state, dispatch, deviceSynced, setShowAlertState]);
    const handleInsightClick = (insight: any)=>{
        history.push(`/things?type=${insight.type}`);
    };

    useEffect(()=>{
        if ( ( justLoaded ) && state.auth.user && (myDevices.length < 1)) {
            setJustLoaded(false); 
            fetchDevices();
        } else if ( (justLoaded || !devicesLoaded) && state.auth.user && (myDevices.length > 0) && !deviceSynced) {
            setJustLoaded(false); 
            setTimeout(() => {
                myDevices.forEach(async (eachDevice: any) => {
                    await sleep(100);
                    await fetchReadings(eachDevice);
                });
            }, 300);
        };
    }, [justLoaded, devicesLoaded, fetchDevices, fetchReadings, state.auth.user, myDevices, deviceSynced]);
    
    if (myDevices.length > 0) {
        if (streamDevices.length > 0) {
            insightTypeRef.current = [];
            var keepCategoryData:any = {};
            streamDevices.forEach((element:any) => {
                let types = Object.keys(element.value);
                let values = Object.values(element.value);
                types.forEach((type, key)=>{
                    if (type !== 'timestamp') {
                        if (keepCategoryData[type]) {
                            keepCategoryData[type] = [
                                ...keepCategoryData[type],
                                values[key]
                            ];
                        } else {
                            keepCategoryData[type] = [
                                values[key]
                            ];
                        }
                    }
                });
            });
            for (const fieldType in keepCategoryData) {
                if (Object.prototype.hasOwnProperty.call(keepCategoryData, fieldType)) {
                    const dataByType = keepCategoryData[fieldType];
                    var avgValue = (dataByType.reduce((a:any, b:any) => parseFloat(a) + parseFloat(b)))/(dataByType.length)
                    insightTypeRef.current = [
                        ...insightTypeRef.current,
                        {type: fieldType, value: avgValue, message: `${fieldType}`, items: dataByType.length}
                    ];
                }
            }
        }
    } else {
        if (!haveDevices) {
            insightTypeRef.current = [];
        }
    }

    return (insightTypeRef.current.length > 0)?(
            <div className="insightsGrid">
            {
                    insightTypeRef.current.map((insight:any, key: number)=>{
                        return (insight.type === 'loader')?(
                            <IonCard key={key} mode='ios' className={'gridItem gridItemLoader'} >
                                <p className="msgPart"><IonSkeletonText /></p>
                                <p className="valuePart">
                                    <IonSkeletonText />
                                </p>
                                <IonIcon className="iconPart" icon={radioButtonOff}/>
                                <p className="unitsPart"> <IonSkeletonText /> </p>
                            </IonCard>
                        ):(
                            <IonCard key={key} mode='ios' className={'gridItem'} onClick={()=>{handleInsightClick(insight)}}>
                                <p className="msgPart">Avg. value<br/> <span>{supportedInsights[insight.type].name}</span></p>
                                <div className="valuePart">
                                    {(insight.value).toFixed(2)}
                                </div>
                                <IonIcon className="iconPart" icon={supportedInsights[insight.type].icon}/>
                                <p className="unitsPart">{insight.items} devices</p>
                            </IonCard>
                        )
                    })
                }
        </div>
    ):(
        <div className="deviceConfigView">
            <br/>
            <br/>
            <br/>
            <p>You do not have any devices linked to your acccount.</p>
            <div className="addWiFiButtonHolder">
                <IonButton size="small" className="addWiFiButton" onClick={gotoConfigure}>Add devices </IonButton>
            </div>
        </div>
    )
}
export default Insights;