import { useContext, useRef, useState } from "react";
import { IonAlert, IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonItemOption, IonItemOptions, IonItemSliding, IonLabel, IonList, IonLoading, IonModal, IonPage, IonTitle, IonToolbar, useIonActionSheet } from "@ionic/react"
import { chevronBack, ellipsisVertical } from "ionicons/icons";
import { AppContext } from "../../contexts/AppContextProvider";

import { collection, deleteDoc, doc } from 'firebase/firestore';
import { ref, remove } from "firebase/database";

import { getCurrentReadings } from "../../Services/State";
import { ShowAlertInterface } from "../../Services/utils";
import { cloudFireStoreDB, realtimeDb } from '../../Services/firebase-config';
import DeviceInfo from '../../components/DeviceInfo';

import './index.css';
import { useHistory } from "react-router";
const ListOfThings:React.FC<any> = ({myPresentingElemRef})=>{
    const { state } = useContext(AppContext);

    var myThingsFormated = useRef([{type: 'loader', value: 7, message: "Avg. pH value", items: 0}, {type: 'loader', value: 0, message: "Avg. Temp. value", items: 0}, {type: 'loader', value: 0, message: "Avg. Temp. value", items: 0}, {type: 'loader', value: 0, message: "Avg. Temp. value", items: 0}]);
    const [showLoadingState, setShowLoading] = useState({showLoadingMessage: "Loading ...", showLoading: false, triggered: false});
    const [showAlertState, setShowAlertState] = useState<ShowAlertInterface>({header: "Alert", subHeader: "If this takes too long, just close tha App and start afresh.", message: "", buttons: [], showAlert: false});
    const [showModal, setShowModal] = useState<any>({content: <></>, thing: {identifier: {}}, show: false});
    const ionListRef:any = useRef();
    const history  = useHistory();
    const [present, dismiss] = useIonActionSheet();

    var deviceReadings = getCurrentReadings(state);
    // console.log("deviceReadings: ", deviceReadings);

    const deviceInfo = (thing: any, focus: string)=>{
        setShowModal((s:any)=>({...s, content: <DeviceInfo deviceInfo={thing} dataGroup={focus} />, thing, show: true}));
    };
    const unpairDevice = async (thing: any)=>{
        var alertStateVarsSuccess = {
            header: "Are you sure?", 
            subHeader: `Device: ${(thing.customName === "")?(thing.identifier.name):(thing.customName)}`, 
            message: "Are you sure that you want to unpair this device from your account?", 
            inputs: [], 
            buttons: [
                {text: 'cancel', role: 'cancel'},
                { text: 'Okay', handler: () => { 
                    var deviceCollectionKey = thing.id;
                    var deviceIdentifier = thing.identifier;
            
                    //Deleting the Firestore document,
                    var collectionRef = collection(cloudFireStoreDB, "devices");
                    var docRef = doc(collectionRef, deviceCollectionKey); // get a reference to the document you want to delete
                    deleteDoc(docRef);  // call the deleteDoc() function on the reference
            
                    //Deleting the RealtimeDB path,
                    const deviceRealtimeDBRef = ref(realtimeDb, `devices/${deviceIdentifier.identifier}`); // get a reference to the Path you want to delete
                    remove(deviceRealtimeDBRef); // call the remove() function on the reference
                    
                    ionListRef?.current?.closeSlidingItems();
                }
            } 
            ]
        };
        setTimeout(() => {
            setShowAlertState((s:any)=>({...s, ...alertStateVarsSuccess, showAlert: true}));
        }, 100);
    }
    const actOnThing = (actions:number, type:string, thing:any)=>{
        if (actions === 0) {
            deviceInfo(thing, type);
        } else {
            present({
                header: `${(thing.customName === "")?(thing.identifier.name):(thing.customName)} Action Sheet`,
                buttons: [
                    { 
                        text: 'Device info' , handler: ()=>{
                            deviceInfo(thing, type);
                        }
                    }, 
                    { 
                        text: 'Unpair Device', handler: ()=>{
                            setTimeout(() => {
                                unpairDevice(thing);
                            }, 300);
                        }
                    }, 
                    { 
                        text: 'Cancel', role: "cancel", handler: ()=>{
                            ionListRef?.current?.closeSlidingItems();
                        }
                    }
                ],
                onDidDismiss: () => { dismiss(); },
            });
        }
    }

    var typeOfThings:any = "";
    if (deviceReadings.length > 0) {
        myThingsFormated.current = [];
        if (history) {
            if (history.location) {
                if (history.location.search && (history.location.search !== "")) {
                    const queryParams = new URLSearchParams(history.location.search);
                    if (queryParams.has("type")) {
                        typeOfThings = queryParams.get("type");
                    }
                }
            }
        }
        deviceReadings.forEach((element:any) => {
            let types = Object.keys(element.value);
            types.forEach((type, key)=>{
                if (type === typeOfThings) {
                    var fullDeviceInfo = {
                        ...element,
                        customName: "",
                        identifier: element.deviceIdentifier.identifier,
                        owenerID: element.deviceIdentifier.ownerID,
                        timeAdded: element.deviceIdentifier.timeAdded,
                        id: element.deviceIdentifier.id,
                        thresholds: element.deviceIdentifier.thresholds,
                        value: element.value[typeOfThings],
                    };
                    delete fullDeviceInfo['deviceIdentifier'];
                    myThingsFormated.current = [
                        ...myThingsFormated.current,
                        fullDeviceInfo
                    ];
                }
            });
        });
    }
    
    return <IonPage >
        <IonHeader mode="ios">
            <IonToolbar mode="ios">
                <IonButtons slot="start">
                    <IonBackButton defaultHref="home" text="Home" icon={chevronBack} />
                </IonButtons>
                <IonTitle>
                    Things
                </IonTitle>
            </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
            <IonHeader collapse="condense" mode="ios">
                <IonToolbar>
                    <IonTitle size="large">Things</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonList ref={ionListRef} mode="ios">
            {
                myThingsFormated.current.map((thing:any, key: number)=>{
                    return (thing.type === 'loader')?(
                        <></>
                    ):(
                        <IonItemSliding key={key} >
                            <IonItem key={key} mode="ios" onClick={()=>{actOnThing(0, typeOfThings, thing)}} button>
                                <IonLabel >
                                <br/>
                                    <div className="thingValue">
                                        {(thing.value)?(parseFloat(thing.value).toFixed(2)):("")}
                                        {
                                            (typeOfThings==="temperature")?(<span className="units">&deg;C</span>):("")
                                        }
                                    </div>
                                    <h2 >
                                        {(thing.customName !== "")?(thing.customName):(thing.identifier.name)}
                                    </h2>
                                    <p>
                                        Date added: {thing.timeAdded}
                                    </p>
                                <br/>
                                </IonLabel> 
                            </IonItem>
                            <IonItemOptions side="end" >
                                <IonItemOption onClick={() => { actOnThing(-1, typeOfThings, thing)}}>
                                    <IonIcon icon={ellipsisVertical}/>
                                    more
                                </IonItemOption>
                            </IonItemOptions>
                        </IonItemSliding>
                    )
                })
            }
            </IonList>
        </IonContent>

        <IonLoading
            mode='ios'
            isOpen={showLoadingState.showLoading}
            onDidDismiss={() => setShowLoading({...showLoadingState, showLoading: false})}
            message={showLoadingState.showLoadingMessage}
        />
        <IonAlert
            mode='ios'
            isOpen={showAlertState.showAlert}
            onDidDismiss={() => setShowAlertState({...showAlertState, showAlert: false})}
            header={showAlertState.header}
            subHeader={showAlertState.subHeader}
            message={showAlertState.message}
            inputs={showAlertState.inputs}
            buttons={showAlertState.buttons}
        />
        <IonModal 
            mode='ios'
            isOpen={showModal.show}
            presentingElement={myPresentingElemRef?.current}
            canDismiss={true}
            onDidDismiss={() => setShowModal((s:any)=>{ionListRef?.current?.closeSlidingItems(); return {...s, stage: "qrCodeScan", show: false}})}
            >
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonButton onClick={() => setShowModal((s:any)=>{ionListRef?.current?.closeSlidingItems(); return {...s, stage: "qrCodeScan", show: false};})}>Close</IonButton>
                    </IonButtons>
                    <IonTitle>{typeOfThings} Reading</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                {
                    showModal.content
                }
            </IonContent>
        </IonModal>
    </IonPage>
}
export default ListOfThings;