import { useContext, useEffect, useMemo, useState } from 'react';
import { RangeValue } from '@ionic/core';
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonIcon, IonLabel, IonRange } from '@ionic/react';
import { flask, flaskOutline, snowOutline, sunnyOutline } from 'ionicons/icons';
import { AppContext } from '../../contexts/AppContextProvider';
import { getCurrentReadings } from '../../Services/State';
import Charts from '../Charts';

import { doc, updateDoc } from 'firebase/firestore';
import { ref, update } from 'firebase/database';

import { cloudFireStoreDB, realtimeDb } from '../../Services/firebase-config';

import './index.css';
import { dateTimeFromSeconds } from '../../Services/utils';
const DeviceInfo:React.FC<any> = ({deviceInfo, dataGroup})=>{
    const { state } = useContext(AppContext);
    const [deviceThresholds, setDeviceThresholds] = useState<any>({});
    const [chartData, setChartData] = useState<any>({});

    var deviceReadings = getCurrentReadings(state);
    var docID = deviceInfo.id;
    var deviceID = deviceInfo.identifier.identifier;
    var currentDevice = deviceReadings.find((d:any)=>(d.deviceIdentifier.identifier.identifier === deviceID));
    var currentDataHistory = useMemo(()=>((currentDevice)?currentDevice.dataHistory:{}), [currentDevice]);

    const setEmittedRange = (kind: string, evt: any)=>{
        // console.log('detail: ', evt.detail);
        let inputRange = evt.detail.value;
        var hasNaN = false;
        for (const key in inputRange) {
            if (Object.prototype.hasOwnProperty.call(inputRange, key)) {
                const element = inputRange[key];
                if (isNaN(element)) {
                    hasNaN = true;
                }
            }
        };
        if (!hasNaN) {
            var thresholds = {...deviceThresholds, [`min-${kind}`]:  inputRange.lower, [`max-${kind}`]:  inputRange.upper};
            setDeviceThresholds((deviceThresholds:any)=>{
                return {...deviceThresholds, [`min-${kind}`]:  inputRange.lower, [`max-${kind}`]:  inputRange.upper}
            });
            const collectionRef = doc(cloudFireStoreDB, "devices", docID);
            updateDoc(collectionRef, { thresholds }).then(res=>{
                // console.log(res);
            });
            update(ref(realtimeDb, `devices/${deviceID}/thresholds`), thresholds).then(() => {
                // console.log("set");
            }).catch((error) => {
                console.log(error);
            });
        }
    }
    useEffect(()=>{
        // console.log(currentDevice);
        if (currentDevice) {
            var currentDeviceThresholds = currentDevice.deviceIdentifier.thresholds;
            setDeviceThresholds(currentDeviceThresholds);
        }
    }, [currentDevice]);
    useEffect(()=>{
        var labels:any = Object.keys(currentDataHistory);
        let values = Object.values(currentDataHistory);
        let tempVals:any = [];
        labels = labels.map((val:number)=>{
            let timestampString:any = dateTimeFromSeconds(val*1000);
            return timestampString;
        });
        values.forEach((val:any)=>{
            tempVals.push(parseFloat(val[dataGroup]));
        });
        let dataObject = {label: dataGroup, data: tempVals};
        var chartPlaceHolder = {
            type: 'line', labels: labels, 
            datasets: [
                dataObject,
            ], 
            scale: {yAxes: {ticks: {beginAtZero: true}}}
        };


        setChartData(chartPlaceHolder);
    }, [currentDataHistory, dataGroup]);
    var tempThresholds:RangeValue = {lower: parseInt((deviceThresholds['min-temperature'])?(deviceThresholds['min-temperature']):(0)), upper: parseInt((deviceThresholds['max-temperature'])?(deviceThresholds['max-temperature']):(100))};
    var pHThresholds:RangeValue = {lower: parseInt((deviceThresholds['min-pH'])?(deviceThresholds['min-pH']):(0)), upper: parseInt((deviceThresholds['max-pH'])?(deviceThresholds['max-pH']):(14))};

    return (
        <div>
            <IonCardHeader>
                <IonCardTitle>
                    {(deviceInfo.customName ==="")?(deviceInfo.identifier.name):(deviceInfo.customName)}
                </IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
                <p>
                    <span className='label'>Custom name: </span> {deviceInfo.customName}
                </p>
                <p>
                    <span className='label'>Device ID: </span> {deviceInfo.identifier.identifier}
                </p>
                <p>
                    <span className='label'>Date addded: </span> {deviceInfo.timeAdded}
                </p>
                <p>
                    <span className='label'>Current Reading: </span> {
                        (dataGroup==="temperature")?(
                            <span className='value'>{deviceInfo.value} &deg;C</span>
                        ):(
                            <span className='value'>{deviceInfo.value }</span>
                        )
                    }
                </p>
                <br/>
                
                {
                    (dataGroup==="temperature")?(
                        <IonCard disabled>
                            <IonLabel>
                                Temperature: min {tempThresholds.lower}, max: {tempThresholds.upper}
                            </IonLabel>
                            <IonRange
                            mode='ios'
                            dualKnobs={true}
                            value={ tempThresholds }
                            min={0}
                            max={100}
                            pin={ true }
                            onIonKnobMoveEnd={(evt)=>{setEmittedRange("temperature", evt)}}
                            >
                                <IonIcon slot="start" icon={snowOutline}></IonIcon>
                                <IonIcon slot="end" icon={sunnyOutline}></IonIcon>
                            </IonRange>
                        </IonCard>
                    ):(
                        <IonCard disabled>
                            <IonLabel>
                                pH: min {pHThresholds.lower}, max {pHThresholds.upper}
                            </IonLabel>
                            <IonRange
                            mode='ios'
                            dualKnobs={true}
                            value={ pHThresholds }
                            min={0}
                            max={14}
                            pin={true}
                            onIonKnobMoveEnd={(evt)=>{setEmittedRange("pH", evt)}}
                            >
                                <IonIcon slot="start" icon={flaskOutline}></IonIcon>
                                <IonIcon slot="end" icon={flask}></IonIcon>
                            </IonRange>
                        </IonCard>
                    )
                }
                <br/>
                <IonCard>
                    {
                        (Object.keys(chartData).length>0)?(<Charts data={chartData}/>):("")
                    }
                </IonCard>
            </IonCardContent>
        </div>
    )
}
export default DeviceInfo;